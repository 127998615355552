import { FunctionArguments } from "@chakra-ui/utils";
import { useMemo } from "react";
import { TitleMetaLinkTag } from "react-datocms";
import { BrandSlug } from "../portalstack.config";
import { getBaseUrlByBrandSlug } from "../lib/config";
import { truthy } from "../utils/misc";
import { Tag } from "../__generated__/types/graphql-codegen";

const normalizeMetaTags = (
	settings: Array<Tag>,
	baseUrl: string,
): Array<TitleMetaLinkTag> => {
	const re = new RegExp(`https*://${process.env.DATO_CDN_HOST}/`, "g");

	return settings.map(({ tag, content, attributes }) => {
		if (
			truthy(attributes) &&
			"content" in attributes &&
			typeof attributes.content === "string"
		) {
			attributes.content = attributes.content.replace(
				re,
				`${baseUrl}/cdn/`,
			);
		}

		if (tag === "meta") {
			return {
				attributes: attributes ?? null,
				content: null,
				tag: "meta",
			};
		}

		return {
			attributes: attributes ?? null,
			content: content ?? null,
			tag: "title",
		};
	});
};

const enrichMetaTags = (
	settings: Array<Tag>,
	brandSlug: BrandSlug,
	fallbackDescription?: string,
	title?: string,
): Array<TitleMetaLinkTag> => {
	const baseUrl = getBaseUrlByBrandSlug(brandSlug);
	const normalized = normalizeMetaTags(settings, baseUrl);

	const description = normalized.find(
		(tag) =>
			tag.attributes &&
			"name" in tag.attributes &&
			tag.attributes.name === "description",
	)?.attributes?.content;

	const tags = normalized.map((tag) => {
		if (!title) {
			return tag;
		}

		if (
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			(tag?.attributes?.property ?? tag?.attributes?.name)?.endsWith(
				":title",
			)
		) {
			return {
				...tag,
				attributes: {
					...tag.attributes,
					content: title,
				},
			};
		}

		return tag;
	});

	const hasDescription = Boolean(description);

	if (!hasDescription && fallbackDescription) {
		const fallbackDescriptions: Array<TitleMetaLinkTag> = [
			{
				attributes: {
					name: "description",
					content: fallbackDescription,
				},
				content: null,
				tag: "meta",
			},
			{
				attributes: {
					name: "twitter:description",
					content: fallbackDescription,
				},
				content: null,
				tag: "meta",
			},
			{
				attributes: {
					// don't blame me, blame the Zucc
					property: "og:description",
					content: fallbackDescription,
				},
				content: null,
				tag: "meta",
			},
		];

		return [...tags, ...fallbackDescriptions];
	}

	return [
		...tags,
		{
			tag: "meta",
			content: null,
			attributes: {
				name: "dc.description",
				content: description ?? fallbackDescription ?? "",
			},
		},
	];
};

export const useEnrichedMetaTags = (
	...args: FunctionArguments<typeof enrichMetaTags>
) => useMemo(() => enrichMetaTags(...args), [args]);
