import React, { useEffect, useRef } from "react";
import {
	chakra,
	ChakraProps,
	Divider,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import { useTranslatedString } from "../../i18n/hooks";
import {
	CompanyWidgetWrapper,
	CompanyWidgetDivider,
	CompanyWidgetHeader,
	CompanyWidgetHeaderLink,
	CompanyWidgetInfoCopyButton,
	CompanyWidgetInfoList,
	CompanyWidgetInfoRow,
	CompanyWidgetInfoRowLeft,
	CompanyWidgetInfoRowRight,
	CompanyWidgetInfoInnerRow,
	CompanyWidgetLink,
	CompanyWidgetLinkLogo,
	CompanyWidgetLogo,
	CompanyWidgetBottom,
	CompanyWidgetStylesProvider,
} from "./parts";
import { Hx } from "../headings";
import { TradingViewWidgetMiniChart } from "../tradingViewWidgets";
import { replaceCDNHostInURL } from "../../lib/cdnRewrite";
import { Link } from "../Link/Link";
import { PdfFile as PdfFileIcon } from "../../icons";
import { normalizeResponsiveImageData } from "../../lib/normalizeGalleryData";
import { Assessment } from "../Assessment/Assessment";
import { MessageKey } from "../../i18n/translate";
import { useCurrencyFormatter } from "../../hooks";
import { trimElementsFromList } from "../../utils/dom";
import { FullCompanyModelFragment } from "../../__generated__/types/graphql-codegen";
import { CompanyWidgetViewConfig } from "../../utils/types";
import { useGTMDocumentClick } from "../../lib/gtm/dataLayer";

export type CompanyWidgetProps = {
	companyWidgetConfig: CompanyWidgetViewConfig;
	company: NonNullable<FullCompanyModelFragment & { id: string }>;
	variant?: string;
};

export const CompanyWidget: ReactFC<ChakraProps & CompanyWidgetProps> = ({
	company,
	companyWidgetConfig,
	variant,
	...props
}) => {
	const t = useTranslatedString();
	const formatCurrency = useCurrencyFormatter();

	const hasAssessment = company.assessmentValue !== "";
	const isAssessmentFullWidth = variant !== "overlay";

	const styles = useMultiStyleConfig("CompanyWidget", {
		desktopBreakpoint: "md",
		hasAssessment,
		variant,
	});

	/**
	 * There's no CSS only way to "trim" excess elements off.
	 * https://codepen.io/moritzjacobs/pen/XWEdgQN
	 *
	 * … this is our best option for now.
	 */
	const ref = useRef(null);

	useEffect(() => {
		trimElementsFromList(
			ref.current,
			"[data-info-row]",
			"[data-divider-wrapper]",
		);
	}, [ref]);

	const handleDocumentClick = useGTMDocumentClick();

	const {
		title,
		showAssessment: [showAssessment],
		showDocuments: [showDocuments, showDocumentsDivider],
		showGenericInfo: [showGenericInfo, showGenericInfoDivider],
		showHeader: [showHeader, showHeaderDivider],
		showLinks: [showLinks, showLinksDivider],
		showMarketSymbols: [showMarketSymbols, showMarketSymbolsDivider],
		showPriceInfo: [showPriceInfo, showPriceInfoDivider],
		showSocialLinks: [showSocialLinks],
		showTradingview: [showTradingView],
	} = companyWidgetConfig;

	// In case each company widget config property has been hidden/disabled
	if (
		!Object.values(companyWidgetConfig).some((value) => {
			return Array.isArray(value) ? value.some(Boolean) : Boolean(value);
		})
	) {
		return null;
	}

	const links = company.links?.links;
	const linksSocial = company.linksSocial?.links;

	const {
		wkn,
		isin,
		price,
		priceChance,
		priceTarget,
		documents,
		address,
		industry,
	} = company;

	return (
		<CompanyWidgetStylesProvider value={styles}>
			<chakra.div ref={ref} {...props}>
				<CompanyWidgetWrapper>
					{Boolean(title) && (
						<Hx mb={6} size="h3">
							{title}
						</Hx>
					)}

					{showHeader && (
						<>
							<CompanyWidgetHeader>
								<CompanyWidgetHeaderLink company={company}>
									<CompanyWidgetLogo
										logo={company.logo}
										name={company.name ?? undefined}
									/>
									<Hx size="sm">{company.name}</Hx>
								</CompanyWidgetHeaderLink>
								<CompanyWidgetInfoCopyButton
									value={company.name ?? undefined}
									label={t("company")}
								/>
							</CompanyWidgetHeader>
							{showHeaderDivider && <Divider variant="solid" />}
						</>
					)}

					<CompanyWidgetInfoList>
						{showMarketSymbols && wkn && (
							<CompanyWidgetInfoRow>
								<CompanyWidgetInfoRowLeft>
									{t("wkn")}
								</CompanyWidgetInfoRowLeft>
								<CompanyWidgetInfoRowRight>
									{wkn}

									<CompanyWidgetInfoCopyButton
										label={t("wkn")}
										value={wkn}
									/>
								</CompanyWidgetInfoRowRight>
							</CompanyWidgetInfoRow>
						)}

						{showMarketSymbols && isin && (
							<CompanyWidgetInfoRow>
								<CompanyWidgetInfoRowLeft>
									{t("isin")}
								</CompanyWidgetInfoRowLeft>
								<CompanyWidgetInfoRowRight>
									{isin}

									<CompanyWidgetInfoCopyButton
										label={t("isin")}
										value={isin}
									/>
								</CompanyWidgetInfoRowRight>
							</CompanyWidgetInfoRow>
						)}

						{showMarketSymbols && showMarketSymbolsDivider && (
							<CompanyWidgetDivider />
						)}

						{showPriceInfo &&
							Object.entries({
								price,
								priceChance,
								priceTarget,
							}).map(([key, valueRaw]) => {
								let value = valueRaw?.toString();

								if (!value) {
									return null;
								}

								if (
									key === "price" &&
									typeof valueRaw === "number"
								) {
									value = `${formatCurrency(valueRaw)}`;
								}
								if (
									key === "priceTarget" &&
									typeof valueRaw === "number"
								) {
									value = `${formatCurrency(valueRaw)}`;
								}
								if (
									key === "priceChance" &&
									typeof valueRaw === "number"
								) {
									value = `${value}%`;
								}

								return (
									<CompanyWidgetInfoRow key={key}>
										<CompanyWidgetInfoRowLeft>
											{t(key as MessageKey)}
										</CompanyWidgetInfoRowLeft>
										<CompanyWidgetInfoRowRight>
											{value}
										</CompanyWidgetInfoRowRight>
									</CompanyWidgetInfoRow>
								);
							})}

						{showPriceInfo && showPriceInfoDivider && (
							<CompanyWidgetDivider />
						)}

						{showGenericInfo && industry && (
							<CompanyWidgetInfoRow>
								<CompanyWidgetInfoRowLeft>
									{t("industry")}
								</CompanyWidgetInfoRowLeft>
								<CompanyWidgetInfoRowRight>
									{industry}
								</CompanyWidgetInfoRowRight>
							</CompanyWidgetInfoRow>
						)}

						{showGenericInfo && address && (
							<CompanyWidgetInfoRow>
								<CompanyWidgetInfoRowLeft>
									{t("address")}
								</CompanyWidgetInfoRowLeft>
								<CompanyWidgetInfoRowRight>
									{address}
								</CompanyWidgetInfoRowRight>
							</CompanyWidgetInfoRow>
						)}

						{showGenericInfo && showGenericInfoDivider && (
							<CompanyWidgetDivider />
						)}

						{showDocuments && documents.length > 0 && (
							<>
								<CompanyWidgetInfoRow>
									<CompanyWidgetInfoRowLeft>
										{t("documents")}
									</CompanyWidgetInfoRowLeft>
								</CompanyWidgetInfoRow>
								<CompanyWidgetInfoRow>
									<CompanyWidgetInfoRowLeft>
										&nbsp;
									</CompanyWidgetInfoRowLeft>
									<CompanyWidgetInfoRowRight>
										{documents.map((doc) => (
											<CompanyWidgetInfoInnerRow
												key={doc.basename}
											>
												<Link
													variant="companyWidget"
													download={true}
													isExternal={true}
													onClick={
														handleDocumentClick
													}
													href={replaceCDNHostInURL(
														doc.url,
													)}
												>
													{doc.title ?? doc.basename}
													<PdfFileIcon
														ml="1"
														fontSize={"120%"}
													/>
												</Link>
											</CompanyWidgetInfoInnerRow>
										))}
									</CompanyWidgetInfoRowRight>
								</CompanyWidgetInfoRow>
								{showDocumentsDivider && (
									<CompanyWidgetDivider />
								)}
							</>
						)}

						{showLinks &&
							links?.map((link) => {
								const logo = normalizeResponsiveImageData(
									link.logo?.responsiveImage,
								);

								return link.url && link.title ? (
									<CompanyWidgetInfoRow
										alignItems="center"
										key={link.id}
									>
										<CompanyWidgetInfoRowLeft>
											<CompanyWidgetLink
												key={link.id}
												url={link.url}
											>
												{link.title}
											</CompanyWidgetLink>
										</CompanyWidgetInfoRowLeft>
										<CompanyWidgetInfoRowRight>
											<CompanyWidgetLink
												key={link.id}
												url={link.url}
											>
												<CompanyWidgetLinkLogo
													responsiveImage={logo}
													format={link.logo?.format}
													alt={`Logo for ${link.title}`}
													url={link.logo?.url}
												/>
											</CompanyWidgetLink>
										</CompanyWidgetInfoRowRight>
									</CompanyWidgetInfoRow>
								) : null;
							})}
						{showLinks && showLinksDivider && (
							<CompanyWidgetDivider />
						)}
					</CompanyWidgetInfoList>

					{showAssessment &&
						company.assessmentValue &&
						company.assessmentLabel && (
							<Assessment
								label={company.assessmentLabel}
								color={
									company.assessmentColor?.hex ?? "base.white"
								}
								value={company.assessmentValue}
								isFullWidth={isAssessmentFullWidth}
							/>
						)}
				</CompanyWidgetWrapper>

				{showSocialLinks && linksSocial && linksSocial.length > 0 && (
					<CompanyWidgetBottom>
						{linksSocial.map((link) => {
							return link.url ? (
								<CompanyWidgetLink
									url={link.url}
									key={link.id}
									isBottomLink={true}
								>
									<CompanyWidgetLinkLogo
										responsiveImage={normalizeResponsiveImageData(
											link.logo?.responsiveImage,
										)}
										isBottomLink={true}
										format={link.logo?.format}
										alt={`Logo for ${link.title}`}
										url={link.logo?.url}
									/>
									{link.title}
								</CompanyWidgetLink>
							) : null;
						})}
					</CompanyWidgetBottom>
				)}
				{company.symbolTradingview && showTradingView && (
					<TradingViewWidgetMiniChart
						mt={2}
						symbol={company.symbolTradingview}
					/>
				)}
			</chakra.div>
		</CompanyWidgetStylesProvider>
	);
};
