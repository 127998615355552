import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";
import { useConsent } from "../cookieConsent";

export const OpenConsentButton: ReactFC<ButtonProps> = ({ ...rest }) => {
	const { openConsentOverlay } = useConsent();

	const handleClick = () => {
		openConsentOverlay?.();
	};

	return <Button variant="secondary-white" onClick={handleClick} {...rest} />;
};
