import Script from "next/script";
import React, {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";

/**
 * CMP provider independent:
 */

type ConsentContextType = {
	fullConsent: boolean;
	necessary: boolean;
	statistics: boolean;
	marketing: boolean;
	openConsentOverlay: (() => void) | null;
};

const defaultConsent: ConsentContextType = {
	fullConsent: false,
	marketing: false,
	necessary: false,
	statistics: false,
	openConsentOverlay: null,
};

export const ConsentContext = createContext<ConsentContextType>(defaultConsent);

export const useConsent = (): ConsentContextType => {
	const cbConsent = useContext(ConsentContext);

	return {
		fullConsent: cbConsent.fullConsent,
		marketing: cbConsent.marketing,
		necessary: cbConsent.necessary,
		statistics: cbConsent.statistics,
		openConsentOverlay: cbConsent.openConsentOverlay,
	};
};

/**
 * Cookiebot
 */

export const CookiebotScript: ReactFC<{
	children: ReactNode;
	cookiebotId?: string;
}> = ({ cookiebotId, children }) => {
	const [consent, setConsent] = useState<ConsentContextType>(defaultConsent);

	useEffect(() => {
		if (!cookiebotId) return () => {};

		const handleAccept = (ev: any) => {
			const { necessary, statistics, marketing } =
				window.Cookiebot.consent;
			const fullConsent = necessary && statistics && marketing;

			setConsent({
				fullConsent,
				necessary,
				statistics,
				marketing,
				openConsentOverlay: () => window.Cookiebot.show(),
			});
		};

		window.addEventListener("CookiebotOnConsentReady", handleAccept, false);
		window.addEventListener("CookiebotOnAccept", handleAccept, false);
		window.addEventListener("CookiebotOnDecline", handleAccept, false);

		return () => {
			window.removeEventListener("CookiebotOnConsentReady", handleAccept);
			window.removeEventListener("CookiebotOnAccept", handleAccept);
			window.removeEventListener("CookiebotOnDecline", handleAccept);
		};
	}, [cookiebotId]);

	if (!cookiebotId) {
		return <>{children}</>;
	}

	return (
		<ConsentContext.Provider value={consent}>
			<Script
				id="CookieBot"
				src="https://consent.cookiebot.com/uc.js"
				data-cbid={cookiebotId}
				data-blockingmode="auto"
				strategy="beforeInteractive"
			/>
			{children}
		</ConsentContext.Provider>
	);
};
