// cf. https://github.com/chakra-ui/chakra-ui/issues/4396

import * as React from "react";
import { useContext, createContext, useMemo } from "react";
import { chakra, Heading, HeadingProps } from "@chakra-ui/react";

const headingLevels = ["h1", "h2", "h3", "h4", "h5", "h6"] as const;
const defaultLevel = "h1";

type HeadingLevel = (typeof headingLevels)[number];

const Context = createContext<HeadingLevel>(defaultLevel);

export const HeadingLevelBoundary: ReactFC<{ level?: HeadingLevel }> = ({
	level,
	children,
}) => {
	const levelFromContext = useContext(Context);

	const increasedLevelFromContext = useMemo(() => {
		if (!level) {
			const index = headingLevels.indexOf(levelFromContext);

			if (index >= headingLevels.length) {
				return headingLevels[headingLevels.length - 1]; // cap at h6
			}

			return headingLevels[index + 1];
		}

		return undefined;
	}, [level, levelFromContext]);

	return (
		<Context.Provider
			value={level ?? increasedLevelFromContext ?? defaultLevel}
		>
			{children}
		</Context.Provider>
	);
};

export const useHeadingLevel = () => useContext(Context);

export const Hx: ReactFC<HeadingProps> = ({ children, as, ...props }) => {
	const asCtx = useHeadingLevel();

	return (
		<Heading as={as ?? asCtx} {...props}>
			{children}
		</Heading>
	);
};

export const FauxHeading: ReactFC<HeadingProps> = ({ children, ...props }) => {
	return (
		<Heading as="div" {...props}>
			{children}
		</Heading>
	);
};

type ChakraH2Props = React.ComponentProps<typeof chakra.h2>;

export const ChakraHx = (props: ChakraH2Props) => {
	const level = useHeadingLevel();
	const H = useMemo(() => chakra[level], [level]);

	return <H {...props} />;
};
