import React, { useContext } from "react";

// Swiper doesn't export this type…
export type CarouselItemContextType = {
	isActive: boolean;
	isVisible: boolean;
	isPrev: boolean;
	isNext: boolean;
};

export const CarouselItemContext = React.createContext<
	CarouselItemContextType | undefined
>(undefined);

export const useCarouselItemContext = () => {
	const ctx = useContext(CarouselItemContext);

	if (!ctx) {
		throw new Error("CarouselItemContext used outside of a carousel?");
	}

	return ctx;
};
