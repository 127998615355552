export const getFromRestAPI = async (
	endpoint: string,
	{ token, baseUrl }: { baseUrl?: string; token?: string },
) => {
	return fetch(`${baseUrl ? baseUrl : ""}/cms-rest-api/${endpoint}`, {
		method: "get",
		headers: {
			"X-Api-Version": "3",
			Authorization: `Bearer ${token}`,
			Accept: "application/json",
		},
	});
};
