import React, { useEffect, useState } from "react";

export const useIsMounted = () => {
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);
	}, []);

	return isMounted;
};

export const NoSSR: ReactFC = ({ children }) => {
	const isMounted = useIsMounted();

	return isMounted ? <>{children}</> : null;
};
