import { ResponsiveImageType } from "react-datocms";
import { GalleryBlockFragment } from "../__generated__/types/graphql-codegen";

export type GalleryImage = {
	id: string;
	title?: string;
	responsiveImage?: ResponsiveImageType;
};

type CMSGalleryData = GalleryBlockFragment["gallery"];
type CMSGalleryImage = CMSGalleryData[number]["responsiveImage"];

export const normalizeResponsiveImageData = (
	ri?: CMSGalleryImage | null,
): ResponsiveImageType | undefined => {
	if (!ri) {
		return undefined;
	}

	return {
		...ri,
		base64: ri.base64 ?? undefined,
		alt: ri.alt ?? undefined,
		title: ri.alt ?? undefined,
	};
};

// This is necessary because the react-datocms doesn't accept null for some values
export const normalizeGalleryData = (
	data: CMSGalleryData,
): Array<GalleryImage> => {
	return data.map((d) => {
		return {
			...d,
			title: d.title ?? undefined,
			responsiveImage: normalizeResponsiveImageData(d.responsiveImage),
		};
	});
};
