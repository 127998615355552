import manifest from "../__generated__/cms-manifest.json";
import { CmsManifestBrandSlug } from "../__generated__/cms-manifest-types";

export type ManifestedInfo = {
	brand: CmsManifestBrandSlug;
	webhookUrl: string | null;
	webhookId: string | null;
};

export const getManifestByBrandSlug = (
	brand: CmsManifestBrandSlug,
): ManifestedInfo => {
	const match = (manifest as Array<ManifestedInfo>).find(
		(m) => m.brand === brand,
	);

	if (!match) {
		throw new Error(`Brand ${brand} not found in CMS manifest`);
	}

	return match;
};
