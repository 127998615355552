import {
	Drawer,
	DrawerBody,
	DrawerContent,
	IconButton,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { useRewriteContext } from "../../context/RewriteContext";
import { useColorMode } from "../../hooks";
import { useSingletonDisclosure } from "../../hooks/useSingletonDisclosure";
import { useTranslatedString } from "../../i18n/hooks";
import { Close, Search as SearchIcon } from "../../icons";
import { Search } from "./Search";
import { StylesProvider } from "./SearchDesktop";

type SearchMobileProps = Record<string, any>;

export const SearchMobile: ReactFC<SearchMobileProps> = ({ ...rest }) => {
	const t = useTranslatedString();
	const { hasSearch } = useRewriteContext();
	const { foregroundColor } = useColorMode();

	const { isOpen, onToggle, onClose } = useSingletonDisclosure();
	const styles = useMultiStyleConfig("Search", {});
	const textInputRef = useRef<HTMLInputElement | null>(null);

	if (!hasSearch) {
		return null;
	}

	return (
		<>
			<IconButton
				data-cy="search-toggle-mobile"
				icon={
					isOpen ? (
						<Close color={foregroundColor} />
					) : (
						<SearchIcon color={foregroundColor} />
					)
				}
				onClick={() => {
					onToggle();
				}}
				aria-label={t("search")}
				variant="unstyled"
				sx={styles.toggle}
			/>
			<Drawer
				isOpen={isOpen}
				onClose={onClose}
				placement="top"
				size="full"
				variant="mobile-menu"
				preserveScrollBarGap
			>
				<DrawerContent sx={styles.drawerContent}>
					<DrawerBody className={isOpen ? "is-active" : ""}>
						{/* overwrite drawer styles context */}
						<StylesProvider value={styles}>
							<Search
								inputRef={textInputRef}
								isOpen={isOpen}
								onClose={onClose}
							/>
						</StylesProvider>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
};
