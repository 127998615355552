import { useScroll } from "@use-gesture/react";
import { useEffect, useRef } from "react";
import { isSSR } from "../utils/misc";

const DOWN = 1;
const UP = -1;

type UseScrollDirectionProps = {
	attribute?: string;
};

export const useScrollDirection = ({
	attribute = "data-scrolldir",
}: UseScrollDirectionProps = {}) => {
	const documentRef = useRef<HTMLElement | null>(null);

	useEffect(() => {
		documentRef.current = document.documentElement;

		// NOTE: when navigating the hook gets rerendered, this will expand the menu
		documentRef.current.setAttribute(attribute, "up");
	}, [attribute]);

	return useScroll(
		({ direction: [_xDirection, yDirection] }) => {
			if (!documentRef.current) {
				return;
			}

			if (yDirection === DOWN) {
				documentRef.current.setAttribute(attribute, "down");

				return;
			}

			if (yDirection === UP) {
				documentRef.current.setAttribute(attribute, "up");
			}
		},
		{
			target: isSSR ? undefined : window,
			threshold: 60,
		},
	);
};
