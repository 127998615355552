import { useContext, createContext, useMemo } from "react";
import { CompanyWidgetViewConfigValue } from "../types.d";
import { ArticleWithUrlPrefix, PressRelease } from "../types/misc";
import { orderCategories } from "../utils/orderCategories";
import { CompanyWidgetViewConfig, MakePropsNonNullable } from "../utils/types";
import { CompanyContextProvider } from "./CompanyContext";

export type ArticleContextType =
	| Omit<
			MakePropsNonNullable<ArticleWithUrlPrefix | PressRelease>,
			"assessmentLabel" | "assessmentColor" | "assessmentValue"
	  >
	| undefined;

const ArticleContext = createContext<ArticleContextType | undefined>(undefined);

export const useArticleContext = () => {
	const ctx = useContext(ArticleContext);

	if (!ctx) {
		throw new Error("ArticleContext used outside of its provider");
	}

	return ctx;
};

export const useArticleCategories = () => {
	const article = useArticleContext();

	return useMemo(
		() => orderCategories(article.categories),
		[article.categories],
	);
};

const normalizeCompanyWidgetConfigValue = (
	str?: string | null,
): CompanyWidgetViewConfigValue => {
	if (str === "yes") {
		return [true, false];
	}
	if (str === "yes-with-divider") {
		return [true, true];
	}

	return [false, false];
};

export const normalizeCompanyWidgetConfig = (
	view?: ArticleWithUrlPrefix["companyWidgetView"] | null,
): CompanyWidgetViewConfig => {
	return {
		title: view?.title ?? undefined,
		showTradingview: normalizeCompanyWidgetConfigValue(
			view?.showTradingview,
		),
		showAssessment: normalizeCompanyWidgetConfigValue(view?.showAssessment),
		showDocuments: normalizeCompanyWidgetConfigValue(view?.showDocuments),
		showGenericInfo: normalizeCompanyWidgetConfigValue(
			view?.showGenericInfo,
		),
		showHeader: normalizeCompanyWidgetConfigValue(view?.showHeader),
		showLinks: normalizeCompanyWidgetConfigValue(view?.showLinks),
		showMarketSymbols: normalizeCompanyWidgetConfigValue(
			view?.showMarketSymbols,
		),
		showPriceInfo: normalizeCompanyWidgetConfigValue(view?.showPriceInfo),
		showSocialLinks: normalizeCompanyWidgetConfigValue(
			view?.showSocialLinks,
		),
	};
};

export const normalizeArticleData = (
	article: ArticleWithUrlPrefix | PressRelease,
): ArticleContextType => {
	const overrideAssessment = Boolean(article.assessmentValue);

	const assessmentValue =
		overrideAssessment && article.assessmentValue
			? article.assessmentValue
			: article.company?.assessmentValue;

	const assessmentLabel =
		overrideAssessment && article.assessmentLabel
			? article.assessmentLabel
			: article.company?.assessmentLabel;

	const assessmentColor =
		overrideAssessment && article.assessmentColor
			? article.assessmentColor
			: article.company?.assessmentColor;

	const company: ArticleWithUrlPrefix["company"] = article.company
		? {
				...article.company,
				assessmentColor,
				assessmentLabel,
				assessmentValue,
		  }
		: undefined;

	return {
		...article,
		content: article.content,
		title: article.title ?? undefined,
		subline: article.subline ?? undefined,
		slug: article.slug ?? undefined,
		company,
		image: article.image ?? undefined,
		showRiskNotice: article.showRiskNotice ?? undefined,
		keyPointsTitle: article.keyPointsTitle ?? undefined,
		keyPointsContent: article.keyPointsContent ?? undefined,
		teaserText: article.teaserText ?? undefined,
		brand: article.brand ?? undefined,
		___urlPrefixMessageKey: article.___urlPrefixMessageKey ?? undefined,
		companyWidgetView: article.companyWidgetView ?? undefined,
	};
};

export const ArticleContextProvider: ReactFC<{
	value: ArticleWithUrlPrefix | PressRelease;
}> = ({ value, children, ...rest }) => {
	const article = normalizeArticleData(value);

	return (
		<ArticleContext.Provider value={article} {...rest}>
			<CompanyContextProvider
				value={
					article?.company
						? {
								company: article.company,
								defaultWidgetViewConfig:
									normalizeCompanyWidgetConfig(
										article.companyWidgetView,
									),
								mobileWidgetViewConfig:
									normalizeCompanyWidgetConfig(
										article.companyWidgetView,
									),
						  }
						: undefined
				}
			>
				{children}
			</CompanyContextProvider>
		</ArticleContext.Provider>
	);
};
