import React from "react";
import {
	ErrorBoundary as ReactErrorBoundary,
	FallbackProps,
} from "react-error-boundary";
import { ErrorMessage, Error, ErrorTitle } from "./Error";

const FallbackComponent: ReactFC<FallbackProps> = ({ error }) => {
	return (
		<Error>
			<ErrorTitle>{error.name}</ErrorTitle>
			<ErrorMessage>{error.message}</ErrorMessage>
		</Error>
	);
};

export const ErrorBoundary: ReactFC = ({ children }) => {
	return (
		<ReactErrorBoundary FallbackComponent={FallbackComponent}>
			{children}
		</ReactErrorBoundary>
	);
};
