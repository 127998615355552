import { extendTheme } from "@chakra-ui/react";
import { useMemo } from "react";
import { BrandSlug, themeDict } from "../portalstack.config";
import { theme } from "./brandThemes/baseTheme";

// DO NOT remove this export, we need it in the storybook decorators!
export const getBrandTheme = (brandSlug: BrandSlug) => {
	const brandThemeList = brandSlug in themeDict ? themeDict[brandSlug] : [];

	const extended = extendTheme(theme, ...brandThemeList);

	return extended;
};

export const useBrandTheme = (brandName: BrandSlug) => {
	return useMemo(() => getBrandTheme(brandName), [brandName]);
};
