import { chakra, ChakraProps } from "@chakra-ui/react";
import React, { useContext } from "react";
import { Image as ImageOrig, ResponsiveImageType } from "react-datocms";
import { CarouselItemContext } from "../context/CarouselItemContext";
import { replaceCDNHostInObject } from "../lib/cdnRewrite";

export type CMSImageProps = ChakraProps & {
	responsiveImage: ResponsiveImageType;
	title?: string | null;
	pictureStyle?: React.CSSProperties;
	divStyle?: React.CSSProperties;
	layout?: "fill" | "responsive";
	alt: string;
};

export const CMSImage: ReactFC<CMSImageProps> = ({
	children,
	responsiveImage: originalData,
	title,
	pictureStyle,
	divStyle,
	alt,
	layout = "responsive",
	...rest
}) => {
	const carouselContext = useContext(CarouselItemContext);
	const isInCarousel = Boolean(carouselContext);

	const data = replaceCDNHostInObject(originalData);

	return (
		<chakra.figure
			{...rest}
			__css={{
				// this is a lighthouse hack
				"img[role=presentation]": {
					objectFit: "cover",
				},
			}}
			data-chromatic="ignore"
		>
			<ImageOrig
				data={{ ...data, alt }}
				style={divStyle}
				layout={layout}
				pictureStyle={pictureStyle}
				// TODO: maybe we can lazyload gallery images in a smarter way, for now let's turn that off
				lazyLoad={!isInCarousel}
			/>
			{title && <chakra.figcaption>{title}</chakra.figcaption>}
			{children}
		</chakra.figure>
	);
};
