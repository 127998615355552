import { useCallback } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { useBrandContext } from "../context/BrandContext";
import { BrandSlug } from "../portalstack.config";
import { MessageKey, messages } from "./translate";

export const getTranslatedMessage = (
	id: MessageKey,
	formatMessage: (
		desc: MessageDescriptor,
		values?: Record<string, any>,
	) => string,
	...args: Array<Record<string, any>>
) => formatMessage(messages[id], ...args);

export const useTranslatedString = () => {
	const intl = useIntl();
	const getMessage = useCallback(
		(id: MessageKey, ...args: Array<Record<string, any>>) => {
			const descriptor = messages[id];

			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return intl.formatMessage(descriptor, ...args);
		},

		[intl],
	);

	return getMessage;
};

export const useBrandTranslatedString = (fallbackBrandSlug: BrandSlug) => {
	const { slug: brandSlug } = useBrandContext();

	const familySlug = brandSlug?.split(".")?.[0];

	const t = useTranslatedString();

	return (messageKey: string) => {
		const fullMessageKey = `${
			familySlug ?? fallbackBrandSlug
		}${messageKey}`;

		if (fullMessageKey in messages) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return t(fullMessageKey as MessageKey);
		}

		throw new Error(`Branded message not found: ${fullMessageKey}`);
	};
};
