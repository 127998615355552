import { MessageKey } from "../i18n/translate";
import { truthy } from "../utils/misc";
import { ApiSearchResult, ApiSearchResultType } from "../utils/types";

export type SortedResults = Array<{
	type: ApiSearchResultType;
	titleKey: MessageKey;
	highestScore: number;
	items: Array<ApiSearchResult & { index: number }>;
}>;
export const sortResults = (
	results: Array<ApiSearchResult>,
	startingIndex = 0,
): SortedResults => {
	const { articles, pages } = results.reduce<{
		articles: Array<ApiSearchResult>;
		pages: Array<ApiSearchResult>;
	}>(
		(prev, curr) => {
			if (curr.type === ApiSearchResultType.Article) {
				prev.articles.push(curr);
			}

			if (curr.type === ApiSearchResultType.StaticPage) {
				prev.pages.push(curr);
			}

			return prev;
		},
		{
			articles: [],
			pages: [],
		},
	);

	const highestRankingArticle = articles.reduce((prev, curr) => {
		return Math.max(prev, curr.score);
	}, 0);

	const highestRankingPage = pages.reduce((prev, curr) => {
		return Math.max(prev, curr.score);
	}, 0);

	// build final array with headings
	const finalResults = [
		articles.length > 0 && {
			type: ApiSearchResultType.Article,
			items: articles,
			highestScore: highestRankingArticle,
			titleKey: "article" as const,
		},
		pages.length > 0 && {
			type: ApiSearchResultType.StaticPage,
			items: pages,
			highestScore: highestRankingPage,
			titleKey: "page" as const,
		},
	]
		.filter(truthy)
		.sort((a, b) => b.highestScore - a.highestScore)
		// calc correct index for focus
		.map((data, i, dataArr) => {
			return {
				...data,
				items: data.items.map((item, index) => ({
					...item,
					index:
						startingIndex + i === 0
							? index
							: index + (dataArr[i - 1]?.items.length ?? 0),
				})),
			};
		});

	return finalResults;
};

// export const mergeTwoSortedSearchResults = (
// 	a: SortedResults,
// 	b: SortedResults,
// ) => {
// 	const articlesFromA =
// 		a.find((r) => r.type === ApiSearchResultType.Article)?.items ?? [];
// 	const articlesFromB =
// 		b.find((r) => r.type === ApiSearchResultType.Article)?.items ?? [];
// 	const pagesFromA =
// 		a.find((r) => r.type === ApiSearchResultType.StaticPage)?.items ?? [];
// 	const pagesFromB =
// 		b.find((r) => r.type === ApiSearchResultType.StaticPage)?.items ?? [];

// 	const merged = [
// 		...articlesFromA,
// 		...articlesFromB,
// 		...pagesFromA,
// 		...pagesFromB,
// 	];

// 	return sortResults(merged.filter(duplicateRecordsById));
// };
