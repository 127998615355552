import { theme } from "@chakra-ui/react";
import { BrandSlug } from "../portalstack.config";
import {
	GetArticlesByBrandQuery,
	GetLandingPageBySlugQuery,
	GetPressReleasesByCompanyIdQuery,
	SiteLocale,
} from "../__generated__/types/graphql-codegen";

/**
 * This is basically the Required<T> type from TypeScript but it also removes null from the Objects properties.
 * it maps through all properties and removes the ?, hence -? and also makes the value of the property NonNullable
 */

export type MakePropsNonNullable<T> = {
	[P in keyof T]: NonNullable<T[P]>;
};

export type Article = GetArticlesByBrandQuery["articles"][number] & {
	highlighted?: boolean;
};

export type LandingPage = NonNullable<GetLandingPageBySlugQuery["landingPage"]>;
export type PressReleases = NonNullable<
	GetPressReleasesByCompanyIdQuery["allPressReleases"]
>;
export type WalledCompany = NonNullable<LandingPage["company"]>;

export type CrossPromoData = {
	articles: Array<Article>;
	locale: SiteLocale;
	popularArticles: Array<Article>;
	brandSlug: BrandSlug;
};

export type BreakpointKeys =
	| keyof typeof theme.breakpoints
	| "smmd"
	| "mdlg"
	| "lgxl";

export type DatoClientSearchResult = {
	id: string;
	attributes: {
		title: string;
		// eslint-disable-next-line @typescript-eslint/naming-convention
		body_excerpt: string;
		url: string;
		score: number;
	};
};

export enum ApiSearchResultType {
	"StaticPage",
	"Article",
}

export type ApiSearchResult = {
	id: string;
	title: string;
	url: string;
	pathname: string;
	type: ApiSearchResultType;
	typeName: string | null;
	score: number;
	slug: string | null;
	bodyExcerpt: string | null;
};

export type DisallowUndefined<T> = {
	[P in keyof T]-?: T[P] | null;
};

export type ApiReturnType<T> = {
	data: T | null;
	error: any | null;
};

export type AddParameters<
	TFunction extends (...args: any) => any,
	TParameters extends [...args: any],
> = (
	...args: [...Parameters<TFunction>, ...TParameters]
) => ReturnType<TFunction>;

type JsonMap = {
	[member: string]: string | number | boolean | null | JsonArray | JsonMap;
};

type JsonArray = Array<string | number | boolean | null | JsonArray | JsonMap>;

export type Json = JsonMap | JsonArray | string | number | boolean | null;

export type PageType = "landingPage" | "default";

export type CompanyWidgetViewInputValue = "yes" | "no" | "yes-with-divider";
export type CompanyWidgetViewConfigValue = [boolean, boolean];

export type CompanyWidgetViewConfig = {
	showAssessment: CompanyWidgetViewConfigValue;
	showDocuments: CompanyWidgetViewConfigValue;
	showGenericInfo: CompanyWidgetViewConfigValue;
	showHeader: CompanyWidgetViewConfigValue;
	showLinks: CompanyWidgetViewConfigValue;
	showMarketSymbols: CompanyWidgetViewConfigValue;
	showPriceInfo: CompanyWidgetViewConfigValue;
	showSocialLinks: CompanyWidgetViewConfigValue;
	showTradingview: CompanyWidgetViewConfigValue;
	title?: string;
};
