import { ResponsiveImageType } from "react-datocms";
import { fromEntries } from "../utils/misc";

const getRegex = (originalCdnHost = process.env.DATO_CDN_HOST) =>
	new RegExp(`https*://${originalCdnHost}/`, "g");

export const replaceCDNHostInObject = (
	data: ResponsiveImageType,
	originalCdnHost?: string,
) => {
	const rewriteKeys: Array<keyof ResponsiveImageType> = [
		"src",
		"srcSet",
		"webpSrcSet",
	];

	return fromEntries(
		Object.entries<unknown>(data).map(([k, v]) => {
			if (
				rewriteKeys.includes(k as keyof ResponsiveImageType) &&
				typeof v === "string"
			) {
				return [k, replaceCDNHostInURL(v, originalCdnHost)];
			}

			return [k, v];
		}),
	) as ResponsiveImageType;
};

export const replaceCDNHostInURL = (
	URL: string,
	originalCdnHost?: string,
	baseUrl?: string,
) => {
	const re = getRegex(originalCdnHost);

	return URL.replace(re, `${baseUrl ?? ""}/cdn/`);
};
