import { ChakraProps } from "@chakra-ui/react";
import { Link } from "../Link/Link";
import { useTranslatedString } from "../../i18n/hooks";
import { joinPaths, truthy } from "../../utils/misc";
import { useGTMTrackCompanyNameClick } from "../../lib/gtm/dataLayer";
import { useCompanyWidgetStyles } from "./parts";
import { ArticleWithUrlPrefix } from "../../types/misc";

export const CompanyWidgetHeaderLink: ReactFC<
	ChakraProps & { company: ArticleWithUrlPrefix["company"] }
> = ({ company, children, ...rest }) => {
	const styles = useCompanyWidgetStyles();
	const t = useTranslatedString();
	const trackCompanyClick = useGTMTrackCompanyNameClick(
		company?.name ?? undefined,
		company?.wkn ?? undefined,
	);

	if (!company?.slug || !company.name || !company.isin) {
		return <>{children}</>;
	}

	const companyOverviewHref =
		"/" +
		joinPaths(
			t("urlSlugStock"),
			[company.slug, company.isin].filter(truthy).join("-"),
		);

	return (
		<Link
			{...rest}
			variant="no-underline"
			href={companyOverviewHref}
			sx={styles.headerLink}
			onClick={trackCompanyClick}
			title={t("allCompanyArticles", {
				name: company.name,
			})}
		>
			{children}
		</Link>
	);
};
