/* eslint-disable react/no-array-index-key */
import { createElement, Fragment, ReactElement } from "react";
import { truthy } from "./misc";

export const renderMarkdownEms = (template: string): ReactElement => {
	const symbol = "*";
	const regexp = new RegExp(`\\${symbol}(\\w+)\\${symbol}`, "g");

	const stringsToWrap = template.match(regexp);
	const parts = template
		.split(regexp)
		.filter(truthy)
		.map((str, i) => {
			if (stringsToWrap) {
				const el = stringsToWrap.find((s) => s.includes(str));

				if (el) {
					return createElement("em", { key: i }, str);
				}

				return createElement(Fragment, { key: i }, str);
			}

			return createElement(Fragment, { key: i }, str);
		});

	return createElement(Fragment, {}, parts);
};
