import { getTranslatedString } from "../i18n/allLocales";
import { SiteLocale } from "../__generated__/types/graphql-codegen";
import {
	ApiSearchResultType as ResultType,
	DatoClientSearchResult,
	ApiSearchResult,
} from "./types";

const getSlugFromPath = (
	pathname: string,
	urlPrefixMessageKeys: Array<string>,
) => {
	const pathnameParts = pathname.split("/").slice(1);

	if (pathnameParts.some((pp) => urlPrefixMessageKeys.includes(pp))) {
		const slugWithDate = pathnameParts.find((part) => part.includes("--"));

		return slugWithDate?.split("--")[0];
	}

	return pathnameParts[0];
};

export const normalizeSearchResult = (
	{
		id,
		attributes: { url, title, score, body_excerpt: bodyExcerpt },
	}: DatoClientSearchResult,
	locale: SiteLocale,
): ApiSearchResult => {
	const articleUrlPrefixMessageKey = getTranslatedString(
		"urlSlugArticle",
		locale,
	);
	const pressReleaseUrlPrefixMessageKey = getTranslatedString(
		"urlSlugPressRelease",
		locale,
	);
	const pathname = new URL(url).pathname;
	const pathnameParts = pathname.split("/").slice(1);
	const type: ResultType =
		pathnameParts[0] === pressReleaseUrlPrefixMessageKey ||
		pathnameParts[0] === articleUrlPrefixMessageKey
			? ResultType.Article
			: ResultType.StaticPage;

	const slug =
		getSlugFromPath(pathname, [
			articleUrlPrefixMessageKey,
			pressReleaseUrlPrefixMessageKey,
		]) ?? null;

	return {
		id,
		pathname,
		type,
		typeName: ResultType[type],
		score,
		url,
		title,
		slug,
		bodyExcerpt,
	};
};
