import { ListItem } from "@chakra-ui/react";
import React, { useCallback } from "react";
import {
	LinkClickMouseEvent,
	useGTMNavbarClick,
} from "../../lib/gtm/dataLayer";
import { renderMarkdownEms } from "../../utils/renderMarkdownEms";
import { GetBrandSettingsQuery } from "../../__generated__/types/graphql-codegen";
import { Link } from "../Link/Link";

type MenuItemProps = NonNullable<
	NonNullable<GetBrandSettingsQuery["settings"]>["mainMenuItems"]
>[number] & {
	variant?: string;
	index: number;
	className?: string;
	onClick?: (ev: LinkClickMouseEvent) => void;
};

const MenuItemNoMemo: ReactFC<MenuItemProps> = ({
	index,
	variant = "menu-link-desktop",
	className,
	onClick,
	...item
}) => {
	const trackClick = useGTMNavbarClick();

	const handleClick = useCallback(
		(ev: LinkClickMouseEvent, label: string) => {
			onClick?.(ev);
			trackClick(ev, index, label);
		},
		[index, trackClick, onClick],
	);

	switch (item.__typename) {
		case "SlugRecord": {
			if (!item.slug || !item.label) {
				return null;
			}

			const { slug, label } = item;

			return (
				<ListItem key={label} className={className}>
					<Link
						onClick={(ev) => handleClick(ev, label)}
						href={slug}
						variant={variant}
					>
						{renderMarkdownEms(label)}
					</Link>
				</ListItem>
			);
		}

		case "InternalLinkRecord": {
			if (!item.link || (!item.link.title && !item.label)) {
				return null;
			}

			const { link, label } = item;

			if (!link.slug || !link.title) {
				return null;
			}

			const title: string = label ?? link.title;

			const labelRendered = renderMarkdownEms(title);

			return (
				<ListItem key={label ?? link.title} className={className}>
					{link.__typename === "BrandRecord" ? (
						<Link
							onClick={(ev) => handleClick(ev, title)}
							brand={link.slug}
							href="/"
							variant={variant}
						>
							{labelRendered}
						</Link>
					) : (
						<Link
							onClick={(ev) => handleClick(ev, title)}
							href={`/${link.slug}`}
							variant={variant}
						>
							{labelRendered}
						</Link>
					)}
				</ListItem>
			);
		}

		default:
			return null;
	}
};

export const MenuItem = React.memo(MenuItemNoMemo);
MenuItem.displayName = "MenuItem";
