import React, { createContext, useContext } from "react";
import { truthy } from "../utils/misc";
import { LandingPage } from "../utils/types";
import { BrandSettings } from "./brandSettings";
import { findValueByKeyRecursive, textToFragmentId } from "./utils";
import { getTranslatedString } from "../i18n/allLocales";
import { getBrandBySlug } from "./config";
import { BrandSlug } from "../portalstack.config";

export const replaceMainMenuWithJumpables = (
	brandSettings: BrandSettings,
	landingPage: LandingPage,
) => {
	const anchorLinks = (landingPage.sectionsLink?.sections ?? [])
		.map((section) => {
			switch (section.__typename) {
				case "SectionsNewsletterRecord": {
					if (!brandSettings.slug) {
						return undefined;
					}

					const { locale } = getBrandBySlug(
						brandSettings.slug as BrandSlug,
					);

					const subscribe = getTranslatedString(
						"landingPageSubscribeMenuItem",
						locale,
					);

					const id = textToFragmentId(subscribe);

					return {
						__typename: "SlugRecord" as const,
						id,
						label: subscribe,
						slug: `#${id}`,
						className: "subscribe",
					};
				}
				default: {
					const title = findValueByKeyRecursive(section, "title");
					const isJumpable = findValueByKeyRecursive(
						section,
						"isJumpable",
					);

					if (
						typeof title !== "string" ||
						!truthy(title) ||
						typeof isJumpable !== "boolean" ||
						!isJumpable
					) {
						return undefined;
					}

					const id = textToFragmentId(title);

					return {
						__typename: "SlugRecord" as const,
						id,
						label: title,
						slug: `#${id}`,
					};
				}
			}
		})
		.filter(truthy);

	return {
		brandSettings: {
			...brandSettings,
			mainMenuItems: anchorLinks,
		},
		menuItems: anchorLinks,
	};
};

type SectionNavigatorContextType = {
	allSections: Array<string>;
	activeSection: string | undefined;
	setActiveSection: React.Dispatch<React.SetStateAction<string | undefined>>;
};
const SectionNavigatorContext = createContext<
	SectionNavigatorContextType | undefined
>(undefined);

export const useSectionNavigatorContext = () => {
	const ctx = useContext(SectionNavigatorContext);

	return ctx;
};

export const SectionNavigatorContextProvider: ReactFC<{
	value: SectionNavigatorContextType;
}> = ({ value, children }) => {
	return (
		<SectionNavigatorContext.Provider value={value}>
			{children}
		</SectionNavigatorContext.Provider>
	);
};
