import React, { useCallback, useMemo } from "react";
import { useRewriteContext } from "../../context/RewriteContext";
import {
	ArticleTrackingType,
	LinkClickMouseEvent,
	useGTMSponsoredPostClick,
	useGTMTrackArticleTeaserClick,
} from "../../lib/gtm/dataLayer";
import {
	CanonicalArticleUrlParams,
	getCanonicalHref,
} from "../../utils/getStaticPropsUtils";
import { SiteLocale } from "../../__generated__/types/graphql-codegen";
import { Link, LinkProps } from "./Link";

type ArticleLinkProps = Omit<LinkProps, "href"> & {
	urlParams: CanonicalArticleUrlParams;
	locale?: SiteLocale;
	trackingType?: ArticleTrackingType;
	isSponsoredPost?: boolean;
};

export const ArticleLink: ReactFC<ArticleLinkProps> = ({
	urlParams,
	locale,
	trackingType,
	isSponsoredPost,
	...rest
}) => {
	const { locale: currentLocale, slug: currentSlug } = useRewriteContext();
	const isExternal =
		Boolean(urlParams.targetBrandSlug) &&
		urlParams.targetBrandSlug !== currentSlug;
	const trackClick = useGTMTrackArticleTeaserClick(trackingType);
	const trackClickExternal =
		useGTMTrackArticleTeaserClick("subbrand_clickout");
	const trackSponsoredClick = useGTMSponsoredPostClick();

	const handleClick = useCallback(
		(ev: LinkClickMouseEvent) => {
			trackClick(ev);
			if (isExternal) {
				trackClickExternal(ev);
			}
			if (isSponsoredPost) {
				trackSponsoredClick(ev);
			}
		},
		[
			isExternal,
			isSponsoredPost,
			trackClick,
			trackClickExternal,
			trackSponsoredClick,
		],
	);

	const articleHref = useMemo(
		() => getCanonicalHref(urlParams, locale ?? currentLocale, currentSlug),
		[currentLocale, locale, urlParams, currentSlug],
	);

	return (
		<Link
			onClick={handleClick}
			href={articleHref}
			height="100%"
			{...rest}
		/>
	);
};
