import { chakra, ChakraProps, useStyleConfig } from "@chakra-ui/react";
import { isDarkColor } from "../../utils/misc";

export const Assessment: ReactFC<
	ChakraProps & {
		color: string;
		label: string;
		value: string;
		variant?: string;
		isFullWidth?: boolean;
	}
> = ({ color, label, value, isFullWidth = false, variant, ...rest }) => {
	const styles = useStyleConfig("Assessment", {
		desktopBreakpoint: "md",
		variant,
	});

	return (
		<chakra.div
			// ignore this element because we can't control the colors in code
			data-axe-ignore
			data-testid="assessment"
			__css={styles}
			backgroundColor={color}
			color={isDarkColor(color) ? "base.white" : "base.black"}
			className={isFullWidth ? "full-width" : ""}
			{...rest}
		>
			<span>{label}</span>
			<span>{value}</span>
		</chakra.div>
	);
};
