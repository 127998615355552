import {
	chakra,
	createStylesContext,
	useMultiStyleConfig,
	Container,
} from "@chakra-ui/react";
import React from "react";
import { TopBanner } from "./TopBanner";
import { DesktopMenu } from "./DesktopMenu";
import { MobileMenu } from "./MobileMenu";
import { isDarkColor } from "../../utils/misc";
import { HeaderProps } from "./types";
import { TrackingContextProvider } from "../../lib/gtm/TrackingContext";

export const [StylesProvider, useHeaderStyles] = createStylesContext("Header");

export const Header: ReactFC<HeaderProps> = ({
	mainMenuItems,
	secondaryMenuItems,
	desktopBreakpoint = "lg",
	topBanner,
	...rest
}) => {
	const styles = useMultiStyleConfig("Header", {});

	const topBannerCompany =
		topBanner?.link.__typename === "ArticleRecord"
			? topBanner.link.company
			: undefined;

	return (
		<StylesProvider value={styles}>
			<chakra.div __css={styles.wrapper}>
				<chakra.header
					data-datocms-noindex
					__css={styles.header}
					{...rest}
					data-cy="header"
				>
					<chakra.div __css={styles.container}>
						<Container sx={styles.inner}>
							<DesktopMenu
								mainMenuItems={mainMenuItems}
								display={{
									base: "none",
									[desktopBreakpoint]: "flex",
								}}
							/>
							<MobileMenu
								mainMenuItems={mainMenuItems}
								secondaryMenuItems={secondaryMenuItems}
								display={{
									base: "flex",
									[desktopBreakpoint]: "none",
								}}
							/>
						</Container>
					</chakra.div>

					{topBanner?.link && topBanner.label ? (
						<TrackingContextProvider
							value={{
								article: {
									title: topBanner.label,
									_firstPublishedAt:
										topBanner.link._firstPublishedAt,
									company: {
										name:
											topBannerCompany?.name ?? undefined,
										wkn: topBannerCompany?.wkn ?? undefined,
									},
								},
							}}
						>
							<TopBanner
								bg={topBanner.color}
								color={
									isDarkColor(topBanner.color)
										? "base.white"
										: "base.black"
								}
								link={topBanner.link}
							>
								{topBanner.label}
							</TopBanner>
						</TrackingContextProvider>
					) : null}
				</chakra.header>
			</chakra.div>
			<chakra.div __css={styles.spacing} />
		</StylesProvider>
	);
};
