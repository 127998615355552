import React from "react";
import { Button, ButtonProps } from "@chakra-ui/react";
import { useTranslatedString } from "../../i18n/hooks";
import { useClipboardInteraction } from "../../hooks/useClipboardInteraction";
import { ToastComponent } from "../ToastComponents/ToastComponents";
import { useRewriteContext } from "../../context/RewriteContext";

export type ClipboardButtonProps = Omit<ButtonProps, "value"> & {
	value: string;
	label?: string;
};

export const ClipboardButton: ReactFC<ClipboardButtonProps> = ({
	value,
	label = "",
	onClick,
	...rest
}) => {
	const t = useTranslatedString();
	const ctx = useRewriteContext();
	const { hasCopied, onCopy } = useClipboardInteraction(value, ctx.slug);

	return (
		<Button
			aria-label={t("copyLong", { label })}
			variant="secondary-black"
			size="xs"
			minW="4.5rem" // seems about right considering the text content
			onClick={(ev) => {
				onCopy((props) => (
					<ToastComponent
						title={t("copiedLong", { label })}
						{...props}
					/>
				));
				onClick?.(ev);
			}}
			{...rest}
		>
			{hasCopied ? t("copied") : t("copy")}
		</Button>
	);
};
