import { ChakraProps } from "@chakra-ui/react";
import React from "react";
import { BrandSettings } from "../../lib/brandSettings";
import { normalizeArticleToCanonicalProps } from "../../utils/getStaticPropsUtils";
import { ArticleLink } from "../Link/ArticleLink";
import { Link } from "../Link/Link";
import { useHeaderStyles } from "./Header";

type Props = ChakraProps & {
	link: NonNullable<BrandSettings["topBannerLink"]>;
};

const TopBannerLink: ReactFC<Props> = ({ children, link, ...rest }) => {
	if (link.__typename === "BrandRecord" && link.slug) {
		return (
			<Link brand={link.brand} href={link.slug} {...rest}>
				{children}
			</Link>
		);
	}

	if (link.__typename === "ArticleRecord" && link.slug) {
		return (
			<ArticleLink
				trackingType="top_bar_click"
				urlParams={normalizeArticleToCanonicalProps(link)}
				{...rest}
			>
				{children}
			</ArticleLink>
		);
	}

	if (link.__typename === "PageRecord" && link.slug) {
		return (
			<Link href={`/${link.slug}`} {...rest}>
				{children}
			</Link>
		);
	}

	return <>{children}</>;
};

export const TopBanner: ReactFC<Props> = ({ children, link, ...rest }) => {
	const styles = useHeaderStyles();

	return (
		<TopBannerLink
			data-cy="top-banner"
			link={link}
			sx={styles.topBanner}
			{...rest}
		>
			{children}
		</TopBannerLink>
	);
};
