import { useContext, createContext } from "react";
import { Brand } from "../portalstack.config";
import { ManifestedInfo } from "../lib/cmsManifest";
import { PageType } from "../utils/types";

type RewriteContextType = Brand &
	Omit<ManifestedInfo, "brand" | "webhookUrl" | "webhookId"> & {
		label: string;
		brandId: string;
		pageType?: PageType;
	};

export const RewriteContext = createContext<RewriteContextType | undefined>(
	undefined,
);

export const useRewriteContext = () => {
	const ctx = useContext(RewriteContext);

	if (!ctx) {
		throw new Error("RewriteContext used outside of its provider");
	}

	return ctx;
};
