import { useContext } from "react";
import { RewriteContext } from "./context/RewriteContext";
import type { BrandSlug } from "./portalstack.config";
import { useIsDarkTheme } from "./hooks";
import { useShadowedComponent } from "./__generated__/shadowed";

export const useBrandComponents = (forceSlug?: BrandSlug) => {
	const ctx = useContext(RewriteContext);
	const brandSlug = forceSlug ?? ctx?.slug;

	if (!brandSlug) {
		throw new Error("No slug provided and no RewriteContext available");
	}

	return useShadowedComponent(brandSlug);
};

export const useBrandLogo = (
	brandSlug?: BrandSlug,
	forceInverted?: boolean,
) => {
	const { Logo, LogoInverted } = useBrandComponents(brandSlug);
	const isDarkTheme = useIsDarkTheme();
	const isInverted = forceInverted === true || isDarkTheme;

	return isInverted ? LogoInverted : Logo;
};
