import {
	AspectRatio,
	AspectRatioProps,
	chakra,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useTranslatedString } from "../../i18n/hooks";
import { HeadingLevelBoundary, Hx } from "../headings";
import { OpenConsentButton } from "../OpenConsentButton/OpenConsentButton";

export const ConsentPlaceholder: ReactFC<{
	title: string;
	aspectRatio: AspectRatioProps["ratio"];
}> = ({ title, aspectRatio }) => {
	const styles = useMultiStyleConfig("ConsentPlaceholder", {});
	const t = useTranslatedString();

	return (
		<HeadingLevelBoundary>
			<AspectRatio
				// TODAY: fix the color contrast on these
				data-axe-ignore
				ratio={aspectRatio}
			>
				<chakra.div __css={styles.container}>
					<Hx sx={styles.title}>{title}</Hx>
					<chakra.p __css={styles.paragraph}>
						{t("dataPrivacySettingsDescription")}
					</chakra.p>
					<OpenConsentButton>
						{t("dataPrivacySettings")}
					</OpenConsentButton>
				</chakra.div>
			</AspectRatio>
		</HeadingLevelBoundary>
	);
};
