import dynamic from "next/dynamic";
import { useMemo } from "react";

import type {ArticleHeaderProps} from "../brandComponents/shared/ArticleHeader/ArticleHeader"
import type {ArticleMainProps} from "../brandComponents/shared/ArticleMain/ArticleMain"
import type {FooterProps} from "../brandComponents/shared/Footer/Footer"
import type {HomePageProps} from "../brandComponents/shared/HomePage/HomePage"
import type {LandingPageHeaderProps} from "../brandComponents/shared/LandingPageHeader/LandingPageHeader"
import type {LogoProps} from "../brandComponents/shared/Logo/Logo"
import type {LogoInvertedProps} from "../brandComponents/shared/LogoInverted/LogoInverted"
import type {SignetProps} from "../brandComponents/shared/Signet/Signet"
const ArticleHeader = dynamic<ArticleHeaderProps>(async () => import("../brandComponents/shared/ArticleHeader/ArticleHeader").then((m) => m.ArticleHeader));
const ArticleMain = dynamic<ArticleMainProps>(async () => import("../brandComponents/shared/ArticleMain/ArticleMain").then((m) => m.ArticleMain));
const Footer = dynamic<FooterProps>(async () => import("../brandComponents/shared/Footer/Footer").then((m) => m.Footer));
const HomePage = dynamic<HomePageProps>(async () => import("../brandComponents/shared/HomePage/HomePage").then((m) => m.HomePage));
const LandingPageHeader = dynamic<LandingPageHeaderProps>(async () => import("../brandComponents/shared/LandingPageHeader/LandingPageHeader").then((m) => m.LandingPageHeader));
const Logo = dynamic<LogoProps>(async () => import("../brandComponents/shared/Logo/Logo").then((m) => m.Logo));
const LogoInverted = dynamic<LogoInvertedProps>(async () => import("../brandComponents/shared/LogoInverted/LogoInverted").then((m) => m.LogoInverted));
const Signet = dynamic<SignetProps>(async () => import("../brandComponents/shared/Signet/Signet").then((m) => m.Signet));

const maps = {"marketbrief.de": {ArticleHeader: dynamic<ArticleHeaderProps>(async () => import("../brandComponents/marketbrief.de/ArticleHeader/ArticleHeader").then((m) => m.ArticleHeader)),
ArticleMain: dynamic<ArticleMainProps>(async () => import("../brandComponents/marketbrief.de/ArticleMain/ArticleMain").then((m) => m.ArticleMain)),
Footer,
HomePage: dynamic<HomePageProps>(async () => import("../brandComponents/marketbrief.de/HomePage/HomePage").then((m) => m.HomePage)),
LandingPageHeader,
Logo: dynamic<LogoProps>(async () => import("../brandComponents/marketbrief.de/Logo/Logo").then((m) => m.Logo)),
LogoInverted: dynamic<LogoInvertedProps>(async () => import("../brandComponents/marketbrief.de/LogoInverted/LogoInverted").then((m) => m.LogoInverted)),
Signet},"marketjournal.de": {ArticleHeader,
ArticleMain,
Footer,
HomePage,
LandingPageHeader,
Logo: dynamic<LogoProps>(async () => import("../brandComponents/marketjournal.de/Logo/Logo").then((m) => m.Logo)),
LogoInverted: dynamic<LogoInvertedProps>(async () => import("../brandComponents/marketjournal.de/LogoInverted/LogoInverted").then((m) => m.LogoInverted)),
Signet},"markettimes.de": {ArticleHeader: dynamic<ArticleHeaderProps>(async () => import("../brandComponents/markettimes.de/ArticleHeader/ArticleHeader").then((m) => m.ArticleHeader)),
ArticleMain: dynamic<ArticleMainProps>(async () => import("../brandComponents/markettimes.de/ArticleMain/ArticleMain").then((m) => m.ArticleMain)),
Footer,
HomePage: dynamic<HomePageProps>(async () => import("../brandComponents/markettimes.de/HomePage/HomePage").then((m) => m.HomePage)),
LandingPageHeader: dynamic<LandingPageHeaderProps>(async () => import("../brandComponents/markettimes.de/LandingPageHeader/LandingPageHeader").then((m) => m.LandingPageHeader)),
Logo: dynamic<LogoProps>(async () => import("../brandComponents/markettimes.de/Logo/Logo").then((m) => m.Logo)),
LogoInverted: dynamic<LogoInvertedProps>(async () => import("../brandComponents/markettimes.de/LogoInverted/LogoInverted").then((m) => m.LogoInverted)),
Signet},"marketworld.de": {ArticleHeader,
ArticleMain,
Footer,
HomePage,
LandingPageHeader,
Logo,
LogoInverted,
Signet},};

export const useShadowedComponent = (brand: keyof typeof maps) => useMemo(() => maps[brand], [brand]);