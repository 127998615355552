import React from "react";
import { chakra, Flex } from "@chakra-ui/react";
import { SkipNavLink, SkipNavContent } from "@chakra-ui/skip-nav";
import { useTranslatedString } from "../i18n/hooks";
import { BrandSettings } from "../lib/brandSettings";
import { Header } from "./Header/Header";
import { ErrorBoundary } from "./Error/ErrorBoundary";
import { useBrandComponents } from "../brandComponents";
import { useRewriteContext } from "../context/RewriteContext";

export const Content: ReactFC<{
	settings: BrandSettings;
}> = ({ children, settings, ...rest }) => {
	const { Footer } = useBrandComponents();
	const t = useTranslatedString();
	const { isBoxedLayout } = useRewriteContext();

	const topBannerLabel = settings.topBannerLabel
		? settings.topBannerLabel
		: settings.topBannerLink?.title;

	return (
		<Flex
			minH={isBoxedLayout ? 0 : "100vh"}
			flexFlow="column nowrap"
			{...rest}
		>
			<SkipNavLink data-datocms-noindex zIndex="skipLink">
				{t("skipLinkLabel")}
			</SkipNavLink>

			<Header
				mainMenuItems={settings.mainMenuItems}
				secondaryMenuItems={settings.secondaryMenuItems}
				topBanner={
					topBannerLabel && settings.topBannerLink
						? {
								color:
									settings.topBannerColor?.hex ?? "lightGray",
								label: topBannerLabel,
								link: settings.topBannerLink,
						  }
						: undefined
				}
			/>

			<SkipNavContent flex="1" display="flex" flexFlow="column nowrap">
				<chakra.main flex="1" bg="var(--bg-color, transparent)">
					<ErrorBoundary>{children}</ErrorBoundary>
				</chakra.main>

				<Footer
					copyright={settings.copyright}
					columns={settings.footerMenu}
				/>
			</SkipNavContent>
		</Flex>
	);
};
