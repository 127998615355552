import { useRouter } from "next/router";
import { useEffect } from "react";
import {
	useGTMTrackScrollDepth,
	useGTMTrackSessionDuration,
} from "./dataLayer";

export const getGtmScript = (
	gtmId: string,
) => `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`;

export const useGTMNavigationEvents = (gtmId?: string) => {
	const router = useRouter();
	const startSession = useGTMTrackSessionDuration();
	const trackDepth = useGTMTrackScrollDepth();

	useEffect(() => {
		if (!gtmId) {
			// eslint-disable-next-line no-console
			console.warn(`GTM ID not provided.`);

			return () => {};
		}

		const isValidId = gtmId.startsWith("GTM-");

		if (!isValidId) {
			// eslint-disable-next-line no-console
			console.warn(`GTM ID ${gtmId} is invalid?`);

			return () => {};
		}

		let stopSession: (() => void) | undefined;
		let stopDepth: (() => void) | undefined;

		const handleRouteChange = (url: string) => {
			stopSession = startSession();
			stopDepth = trackDepth();
			if (typeof window.gtag !== "undefined") {
				window.gtag("config", gtmId, {
					page_path: url,
				});
			}
		};

		router.events.on("routeChangeComplete", handleRouteChange);

		return () => {
			stopSession?.();
			stopDepth?.();
			router.events.off("routeChangeComplete", handleRouteChange);
		};
	}, [gtmId, router.events, startSession, trackDepth]);
};
