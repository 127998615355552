import React from "react";
import { IntlProvider as DefaultIntlProvider } from "react-intl";
import { SiteLocale } from "../__generated__/types/graphql-codegen";
import { allLocaleMessages } from "../i18n/allLocales";

export const IntlProvider: ReactFC<{ locale: SiteLocale }> = ({
	children,
	locale,
}) => {
	return (
		<DefaultIntlProvider
			locale={locale}
			messages={allLocaleMessages[locale]}
		>
			{children}
		</DefaultIntlProvider>
	);
};
