import {
	chakra,
	ChakraProps,
	forwardRef,
	IconButton,
	Input,
} from "@chakra-ui/react";
import React from "react";
import { useTranslatedString } from "../../i18n/hooks";
import { Search as SearchIcon } from "../../icons";
import { useSearchStyles } from "./SearchDesktop";

type SearchFormProps = {
	isOpen: boolean;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	inputRef: React.MutableRefObject<HTMLInputElement | null>;
	value: string;
	onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
};

export const SearchForm: ReactFC<SearchFormProps & ChakraProps> = forwardRef(
	({ isOpen, value, onChange, inputRef, onSubmit, ...rest }, ref) => {
		const t = useTranslatedString();
		const styles = useSearchStyles();

		return (
			<chakra.form ref={ref} onSubmit={onSubmit} {...rest}>
				<chakra.div __css={styles.inputContainer} role="search">
					<Input
						data-cy="search-input"
						sx={styles.input}
						variant="unstyled"
						onChange={onChange}
						value={value}
						ref={inputRef}
						aria-hidden={!isOpen}
						tabIndex={isOpen ? 0 : -1}
						placeholder={t("searchPlaceholder")}
						isRequired
						errorBorderColor="base.error"
					/>
					<IconButton
						data-cy="search-submit-button"
						sx={styles.submitButton}
						variant="unstyled"
						icon={<SearchIcon color="brand.primary" />}
						aria-label={t("search")}
						aria-hidden={!isOpen}
						tabIndex={isOpen ? 0 : -1}
						type="submit"
					/>
				</chakra.div>
			</chakra.form>
		);
	},
);
