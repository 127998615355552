import { useContext, createContext } from "react";
import { CompanyWidgetViewConfig } from "../utils/types";
import { FullCompanyModelFragment } from "../__generated__/types/graphql-codegen-client";

export type CompanyContextType = {
	company: FullCompanyModelFragment & { id: string };
	defaultWidgetViewConfig?: CompanyWidgetViewConfig;
	mobileWidgetViewConfig?: CompanyWidgetViewConfig;
};

const CompanyContext = createContext<CompanyContextType | undefined>(undefined);

export const useCompanyContext = () => useContext(CompanyContext);

export const CompanyContextProvider: ReactFC<{
	value: CompanyContextType | undefined;
}> = ({ value, ...rest }) => {
	return <CompanyContext.Provider value={value} {...rest} />;
};
