import { chakra, useMultiStyleConfig } from "@chakra-ui/react";
import Head from "next/head";
import { useBrandComponents } from "../brandComponents";
import { useBrandContext } from "../context/BrandContext";
import { useTranslatedString } from "../i18n/hooks";
import { HeadingLevelBoundary, ChakraHx } from "./headings";

export const MaintenancePage: ReactFC = () => {
	const t = useTranslatedString();
	const { label } = useBrandContext();
	const styles = useMultiStyleConfig("MaintenancePage", {});
	const { Signet } = useBrandComponents();
	const title = t("maintenanceModeTitle", { label });

	return (
		<chakra.div __css={styles.container}>
			<Head>
				<title>{title}</title>
			</Head>
			<Signet sx={styles.logo} />
			<chakra.div>
				<ChakraHx sx={styles.title}>{title}</ChakraHx>
				<HeadingLevelBoundary>
					<ChakraHx sx={styles.subtitle}>
						{t("maintenanceModeSubtitle")}
					</ChakraHx>
				</HeadingLevelBoundary>
			</chakra.div>
		</chakra.div>
	);
};
