import { useContext, createContext } from "react";
import { BrandSlug } from "../portalstack.config";
import { BrandSettings } from "../lib/brandSettings";
import { MakePropsNonNullable } from "../utils/types";
import manifest from "../__generated__/cms-manifest.json";

type BrandContextType =
	| MakePropsNonNullable<Omit<BrandSettings, "__typename">>
	| undefined;

const BrandContext = createContext<BrandContextType | undefined>(undefined);

export const isBrandSlug = (slug: unknown): slug is BrandSlug => {
	return Boolean(manifest.find((m) => m.brand === slug));
};

export const useBrandContext = () => {
	const ctx = useContext(BrandContext);

	if (!ctx) {
		throw new Error("BrandContext used outside of its provider");
	}

	return ctx;
};

const normalizeBrandData = (
	brand: BrandSettings | undefined,
): BrandContextType => {
	if (!brand) {
		return undefined;
	}

	return {
		...brand,
		brandImage: brand.brandImage ?? undefined,
		copyright: brand.copyright ?? undefined,
		crossPromo: brand.crossPromo ?? undefined,
		featuredArticles: brand.featuredArticles,
		footerMenu: brand.footerMenu,
		label: brand.label ?? undefined,
		mainMenuItems: brand.mainMenuItems,
		noindex: brand.noindex ?? undefined,
		secondaryMenuItems: brand.secondaryMenuItems,
		seo: brand.seo,
		slug: brand.slug ?? undefined,
		slogan: brand.slogan ?? undefined,
		topBannerColor: brand.topBannerColor ?? undefined,
		topBannerLabel: brand.topBannerLabel ?? undefined,
		topBannerLink: brand.topBannerLink ?? undefined,
		maintenanceMode: brand.maintenanceMode ?? false,
	};
};

export const BrandContextProvider: ReactFC<{
	value: BrandSettings | undefined;
}> = ({ value, ...rest }) => {
	const brand = normalizeBrandData(value);

	return <BrandContext.Provider value={brand} {...rest} />;
};
