import React, { useEffect, useRef } from "react";
import { ApiSearchResult } from "../../utils/types";
import { Link } from "../Link/Link";
import { useSearchStyles } from "./SearchDesktop";

type SearchSingleResultProps = {
	result: ApiSearchResult;
	focus: boolean;
};

export const SearchSingleResult: ReactFC<SearchSingleResultProps> = ({
	result,
	focus,
	...rest
}) => {
	const styles = useSearchStyles();
	const ref = useRef<HTMLAnchorElement | null>(null);

	useEffect(() => {
		if (focus) {
			ref.current?.focus();
		}
	}, [focus]);

	return (
		<Link
			sx={styles.resultsLink}
			role="option"
			href={result.pathname}
			{...rest}
			ref={ref}
			tabIndex={focus ? 0 : -1}
		>
			{result.title}
		</Link>
	);
};
