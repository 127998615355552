import Head from "next/head";

export const SourceDebugOutput = ({ data }: { data: any }) => {
	return (
		<Head>
			<noscript
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{
					__html: `<!-- ${JSON.stringify(data, null, 2)} -->`,
				}}
			/>
		</Head>
	);
};
