import {
	AspectRatio,
	AspectRatioProps,
	chakra,
	ChakraProps,
	useMultiStyleConfig,
	useToken,
} from "@chakra-ui/react";
import {
	Locales,
	StockMarket,
	StockMarketProps,
	MiniChart,
	MiniChartProps,
} from "react-ts-tradingview-widgets";
import React from "react";
import { useRewriteContext } from "../context/RewriteContext";
import { ConsentPlaceholder } from "./Embed/ConsentPlaceholder";
import { useIsMounted } from "./NoSSR";
import { useConsent } from "./cookieConsent";

const TradingViewWidgetContainer: ReactFC<{
	aspectRatio: AspectRatioProps["ratio"];
}> = ({ children, aspectRatio }) => {
	const { fullConsent } = useConsent();

	const isMounted = useIsMounted();
	const styles = useMultiStyleConfig("TradingViewWidget", {});

	return (
		<chakra.div __css={styles.container} data-axe-ignore>
			{/* data-axe-ignore because things in iframes shouldn't concern us */}
			<chakra.div __css={styles.inner}>
				{fullConsent && isMounted ? (
					children
				) : (
					<ConsentPlaceholder
						aspectRatio={aspectRatio}
						title="TradingView"
					/>
				)}
			</chakra.div>
		</chakra.div>
	);
};

export type TradingViewWidgetMiniChartProps = ChakraProps & { symbol?: string };

export const TradingViewWidgetMiniChart: ReactFC<
	TradingViewWidgetMiniChartProps
> = ({ symbol, ...rest }) => {
	const { fullLocale: locale } = useRewriteContext();

	const [plotLineColor] = useToken("colors", ["brand.primary"]);

	const config: MiniChartProps = {
		symbol,
		dateRange: "12M",
		trendLineColor: plotLineColor,
		underLineColor: "transparent",
		underLineBottomColor: "transparent",
		autosize: true,
		colorTheme: "light",
		isTransparent: true,
		largeChartUrl: "",
		locale: locale as Locales,
	};

	return (
		<AspectRatio ratio={1.6} {...rest}>
			{/* data-axe-ignore because things in iframes shouldn't concern us */}
			<TradingViewWidgetContainer data-axe-ignore aspectRatio={1.6}>
				<MiniChart {...config} />
			</TradingViewWidgetContainer>
		</AspectRatio>
	);
};

export const TradingViewStockMarket: ReactFC<ChakraProps> = ({ ...rest }) => {
	const { fullLocale: locale } = useRewriteContext();

	const [plotLineColor, lighterGray] = useToken("colors", [
		"brand.primary",
		"base.lighterGray",
	]);
	const config: StockMarketProps = {
		dateRange: "3M",
		exchange: "FWB",
		showChart: true,
		showSymbolLogo: false,
		showFloatingTooltip: true,
		plotLineColorGrowing: plotLineColor,
		plotLineColorFalling: plotLineColor,
		gridLineColor: "rgba(255, 255, 255, 0)",
		scaleFontColor: "#000",
		belowLineFillColorGrowing: "transparent",
		belowLineFillColorFalling: "transparent",
		belowLineFillColorGrowingBottom: "transparent",
		belowLineFillColorFallingBottom: "transparent",
		symbolActiveColor: lighterGray,
		width: "100%",
		height: "100%",
		colorTheme: "light",
		locale: locale as Locales,
		isTransparent: true,
	};

	return (
		<AspectRatio
			ratio={{ base: 3 / 5, sm: 3 / 4, md: 5 / 3, xl: 3 / 5 }}
			{...rest}
		>
			<TradingViewWidgetContainer
				data-axe-ignore
				aspectRatio={{ base: 3 / 5, sm: 3 / 4, md: 5 / 3, xl: 3 / 5 }}
			>
				<StockMarket {...config} />
			</TradingViewWidgetContainer>
		</AspectRatio>
	);
};
