import {
	chakra,
	Container,
	useMultiStyleConfig,
	UseToastOptions,
} from "@chakra-ui/react";
import React, { memo, ReactNode } from "react";
import { useTranslatedString } from "../../i18n/hooks";
import { CloseButton } from "../CloseButton/CloseButton";
import { HeadingLevelBoundary, Hx } from "../headings";

type SuccessToastComponentProps = Omit<
	UseToastOptions,
	"variant" | "position"
> & {
	onClose: () => void;
	title: string;
	description?: ReactNode;
	isClosable?: boolean;
};

export const ToastComponent: ReactFC<SuccessToastComponentProps> = memo(
	({ id: _id, onClose, title, description, isClosable = true, ...rest }) => {
		const styles = useMultiStyleConfig("Toast", { variant: "success" });
		const t = useTranslatedString();

		return (
			<HeadingLevelBoundary>
				<Container
					{...rest}
					sx={styles.container}
					data-cy="toast-component"
				>
					<chakra.div sx={styles.content}>
						<div>
							<Hx sx={styles.title} size="h4">
								{title}
							</Hx>
							{description && (
								<chakra.p __css={styles.description}>
									{description}
								</chakra.p>
							)}
						</div>
						{isClosable && (
							<CloseButton
								sx={styles.closeButton}
								onClick={onClose}
								aria-label={t("toastCloseLabel")}
							/>
						)}
					</chakra.div>
				</Container>
			</HeadingLevelBoundary>
		);
	},
);

ToastComponent.displayName = "ToastComponent";
