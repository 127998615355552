import NextLink from "next/link";
import {
	Link as ChakraLink,
	LinkProps as ChakraLinkProps,
	forwardRef,
} from "@chakra-ui/react";
import { parseUrl } from "next/dist/shared/lib/router/utils/parse-url";
import { useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { RewriteContext } from "../../context/RewriteContext";
import { defaultBrand, getBaseUrlByBrandSlug } from "../../lib/config";
import { joinPaths, isAbsoluteURL, isSSR } from "../../utils/misc";
import { cn } from "../../utils/strings";
import { Maybe } from "../../__generated__/types/graphql-codegen";
import { useSectionNavigatorContext } from "../../lib/landingpage.utils";

export type LinkProps = Omit<ChakraLinkProps, "as"> & {
	href: string;
	brand?: Maybe<string>;
};
export const Link = forwardRef<LinkProps, "a">(
	(
		{
			href: rawHref,
			brand: forceBrand,
			className,
			isExternal: forceExternal,
			children,
			onClick,
			...rest
		},
		ref,
	) => {
		const router = useRouter();
		const { previewBrand } = router.query;

		const rewriteContext = useContext(RewriteContext);
		const currentBrand = rewriteContext ? rewriteContext.slug : undefined;
		const brand =
			!rawHref.includes("/crp/") && forceBrand
				? forceBrand
				: currentBrand;

		const isExternal = forceExternal ?? isAbsoluteURL(rawHref);
		const isLinkToOtherBrand = brand !== currentBrand;
		const [isActive, setActive] = useState(false);
		const sectionNavigatorContext = useSectionNavigatorContext();

		const href = useMemo(() => {
			let newHref = rawHref;

			if (newHref.startsWith("#")) {
				return newHref;
			}

			if (previewBrand) {
				const parsedUrl = parseUrl(rawHref);

				newHref = `${rawHref}${
					Object.keys(parsedUrl.query).length ? "&" : "?"
				}previewBrand=${previewBrand}`;
			}

			if (isLinkToOtherBrand) {
				newHref = joinPaths(
					getBaseUrlByBrandSlug(brand ?? defaultBrand.slug),
					rawHref,
				);
			}

			return newHref;
		}, [brand, isLinkToOtherBrand, previewBrand, rawHref]);

		useEffect(() => {
			setActive(
				!isSSR &&
					(router.asPath === href ||
						href === `#${sectionNavigatorContext?.activeSection}`),
			);
		}, [
			href,
			router.asPath,
			router.query,
			sectionNavigatorContext?.activeSection,
		]);

		return (
			<NextLink legacyBehavior href={href}>
				<ChakraLink
					data-datocms-noindex
					href={href}
					ref={ref}
					isExternal={isExternal}
					className={cn(className, { "is-active": isActive })}
					onClick={onClick}
					{...rest}
				>
					{children}
				</ChakraLink>
			</NextLink>
		);
	},
);
