import { useGTMTrackCopyButtonClick } from "../../lib/gtm/dataLayer";
import { ClipboardButton } from "../ClipboardButton/ClipboardButton";
import { useCompanyWidgetStyles } from "./parts";

export const CompanyWidgetInfoCopyButton: ReactFC<{
	value?: string;
	label?: string;
}> = ({ children, value, label, ...rest }) => {
	const styles = useCompanyWidgetStyles();
	const track = useGTMTrackCopyButtonClick();

	if (!value) {
		return null;
	}

	return (
		<ClipboardButton
			sx={styles.infoCopyButton}
			value={value}
			label={label}
			onClick={() => {
				label && track("sidebar", label, value);
			}}
			{...rest}
		/>
	);
};
