/**
 * Remove all elements of a specific type from the end of a NodeList,
 * after the last element of another specific type.
 *
 * https://codepen.io/moritzjacobs/pen/XWEdgQN
 */

export const trimElementsFromList = (
	el: HTMLDivElement | null,
	selectorToKeep: string,
	selectorToTrim: string,
) => {
	if (!el) {
		return;
	}
	const keepItems = el.querySelectorAll(selectorToKeep);
	const removeItems = el.querySelectorAll(selectorToTrim);

	const lastKeepItem = [...keepItems].pop();

	removeItems.forEach((removeItem) => {
		if (removeItem.previousElementSibling === lastKeepItem) {
			removeItem.remove();
		}
	});
};
