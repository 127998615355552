import Head from "next/head";
import React from "react";
import { Thing, WithContext } from "schema-dts";

type StructuredContentProps = {
	schema: WithContext<Thing>;
};

export const StructuredContent: ReactFC<StructuredContentProps> = ({
	schema,
}) => {
	return (
		<Head>
			<script type="application/ld+json">{JSON.stringify(schema)}</script>
		</Head>
	);
};
