import {
	ButtonProps,
	useMultiStyleConfig,
	VisuallyHidden,
	chakra,
	forwardRef,
} from "@chakra-ui/react";
import React from "react";
import { useTranslatedString } from "../../i18n/hooks";
import { cn } from "../../utils/strings";

type BurgerButtonProps = { isOpen: boolean } & ButtonProps;

export const BurgerButton = forwardRef<BurgerButtonProps, "button">(
	({ isOpen, className, ...rest }, ref) => {
		const styles = useMultiStyleConfig("BurgerButton", {});
		const t = useTranslatedString();

		return (
			<chakra.button
				{...rest}
				ref={ref}
				__css={styles.hamburger}
				className={cn(className, { "is-active": isOpen })}
			>
				<chakra.span __css={styles.hamburgerBox} aria-hidden="true">
					<chakra.span __css={styles.hamburgerInner} />
				</chakra.span>
				<VisuallyHidden>{t("menu")}</VisuallyHidden>
			</chakra.button>
		);
	},
);
