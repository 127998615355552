import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useRewriteContext } from "../context/RewriteContext";
import { ApiReturnType, ApiSearchResult } from "../utils/types";
import { SearchService } from "./search.service";
import { ITEMS_PER_RESULT_PAGE } from "./utils";

export const useSearch = (
	query: string,
	{
		limit,
		offset,
		...options
	}: { limit?: number; offset?: number } & UseQueryOptions<
		ApiReturnType<Array<ApiSearchResult>>,
		unknown,
		ApiReturnType<Array<ApiSearchResult>>,
		Array<any>
	>,
) => {
	const brand = useRewriteContext();
	const searchService = new SearchService(brand);

	const limitOffsetCacheKey = [];

	// to prevent the cache key from having empty values
	if (limit) limitOffsetCacheKey.push({ limit });
	if (offset) limitOffsetCacheKey.push({ offset });

	return useQuery(
		["search", query, ...limitOffsetCacheKey],
		async () =>
			searchService.search(
				query,
				limit ?? ITEMS_PER_RESULT_PAGE,
				offset ?? 0,
			),
		{
			...options,
		},
	);
};
