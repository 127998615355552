import {
	Container,
	useMultiStyleConfig,
	createStylesContext,
	chakra,
} from "@chakra-ui/react";
import React from "react";
import { Hx } from "../headings";
import { useTranslatedString } from "../../i18n/hooks";
import type { ErrorProps } from "../../pages/_error";

export const [StylesProvider, useErrorStyles] = createStylesContext("Error");

export const ErrorStatusCodeMessage: ReactFC<
	Pick<ErrorProps, "statusCode">
> = ({ statusCode }) => {
	const styles = useErrorStyles();

	const t = useTranslatedString();

	if (statusCode === 404) {
		return <chakra.p __css={styles.message}>{t("404")}</chakra.p>;
	}

	if (statusCode === 500) {
		return <chakra.p __css={styles.message}>{t("500")}</chakra.p>;
	}

	return (
		<chakra.p __css={styles.message}>{t("defaultErrorMessage")}</chakra.p>
	);
};

export const ErrorTitle: ReactFC = ({ children }) => {
	const styles = useErrorStyles();

	return <Hx sx={styles.title}>{children}</Hx>;
};

export const ErrorMessage: ReactFC = ({ children }) => {
	const styles = useErrorStyles();

	return <chakra.p __css={styles.message}>{children}</chakra.p>;
};

export const Error: ReactFC = ({ children, ...rest }) => {
	const styles = useMultiStyleConfig("ErrorPage", {});

	return (
		<Container {...rest} sx={styles.container}>
			<StylesProvider value={styles}>
				<div>{children}</div>
			</StylesProvider>
		</Container>
	);
};
