import { defineMessages, MessageDescriptor } from "react-intl";
import { fromEntries } from "../utils/misc";
import { defaultMessages } from "./messages";

export const messages: Record<MessageKey, MessageDescriptor> = defineMessages(
	fromEntries(
		Object.entries(defaultMessages).map(([id, defaultMessage]) => [
			id,
			{
				id,
				defaultMessage,
			},
		]),
	),
);

export type MessageKey = keyof typeof defaultMessages;
