import { useColorMode as useColorModeOriginal } from "@chakra-ui/color-mode";
import { useBoolean } from "@chakra-ui/react";
import parseISO from "date-fns/parseISO";
import kebabCase from "lodash/kebabCase";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useConsent } from "../components/cookieConsent";
import { BrandSlug } from "../portalstack.config";
import { useArticleContext } from "../context/ArticleContext";
import { useBrandContext } from "../context/BrandContext";
import { useRewriteContext } from "../context/RewriteContext";
import { IncreaseClicksByOneType } from "../lib/clicks.types";
import { getBaseUrlByBrandSlug } from "../lib/config";
import { ApiReturnType } from "../lib/shared.types";
import { ArticleWithUrlPrefix, PressRelease } from "../types/misc";
import { getCanonicalArticleHrefByArticle } from "../utils/getStaticPropsUtils";
import { isSSR, truthy } from "../utils/misc";
import { SiteLocale } from "../__generated__/types/graphql-codegen";

export const useBuildInfo = (brandSlug: BrandSlug) => {
	return useMemo(
		() =>
			process.env.MMG_DEBUG_MODE
				? {
						cms: {
							env: process.env.DATO_ENVIRONMENT,
							includeDrafts: process.env.DATO_INCLUDE_DRAFTS,
						},
						debug: process.env.MMG_DEBUG_MODE,
						git: process.env.GIT_INFO,
						deployment: process.env.VERCEL_INFO,
						deploymentUrl: getBaseUrlByBrandSlug(brandSlug),
				  }
				: { debug: process.env.MMG_DEBUG_MODE },
		[brandSlug],
	);
};

export const useColorMode = () => {
	const { colorMode, toggleColorMode } = useColorModeOriginal();
	const isDarkMode = colorMode === "dark";

	const [foregroundColor, backgroundColor] = ["base.black", "base.white"];

	return {
		colorMode,
		toggleColorMode,
		isDarkMode,
		foregroundColor,
		backgroundColor,
	};
};

export const useIsDarkTheme = () => useColorMode().isDarkMode;

type FormattedDate = {
	formattedDay?: string;
	formattedDate?: string;
};

const formatDate = (
	locale: SiteLocale,
	short?: boolean,
	date?: Date | string,
): FormattedDate => {
	if (!date) {
		return { formattedDay: undefined, formattedDate: undefined };
	}
	const parsedDate = typeof date === "string" ? parseISO(date) : date;

	return {
		formattedDay: parsedDate.toLocaleString(locale, {
			weekday: short ? "short" : "long",
		}),
		formattedDate: `${parsedDate.getDate()} ${parsedDate.toLocaleString(
			locale,
			{
				month: short ? "short" : "long",
			},
		)} ${parsedDate.getFullYear()}`,
	};
};

export const useFormattedDate = (
	date?: Date | string,
	short?: boolean,
	forceLocale?: SiteLocale,
) => {
	const { locale: contextLocale } = useRewriteContext();
	const locale = forceLocale ?? contextLocale;

	return useMemo(
		(): FormattedDate => formatDate(locale, short, date),
		[date, locale, short],
	);
};

export const useCurrentURL = isSSR ? () => "" : () => window.location.href;

export const useClickCounter = (
	article: ArticleWithUrlPrefix | PressRelease,
	brand: BrandSlug,
) => {
	const { statistics } = useConsent();

	useEffect(() => {
		if (isSSR || !statistics) {
			return;
		}

		const increaseClick = async () => {
			const result = await fetch(`/api/clicks`, {
				method: "POST",
				headers: new Headers({
					"Content-Type": "application/json",
				}),
				body: JSON.stringify({
					slug: article.slug,
					brand,
				}),
			});

			const response: ApiReturnType<IncreaseClicksByOneType> =
				await result.json();

			if (response.error) {
				// eslint-disable-next-line no-console
				console.warn(response.error);
			}
		};

		increaseClick();
	}, [article, brand, statistics]);
};

/**
 * returns a boolean that can be used as a hook dependency if you
 * need to wait for mounting, e.g. before querying localstorage
 */
export const useAfterMount = () => {
	const [hasBeenMounted, { on }] = useBoolean(false);

	useEffect(() => {
		on();
	}, [on]);

	return hasBeenMounted;
};

export const useCurrentArticleURL = () => {
	const article = useArticleContext();
	const { locale, slug: brandSlug } = useRewriteContext();

	const baseUrl = getBaseUrlByBrandSlug(brandSlug);

	return useMemo(
		() => baseUrl + getCanonicalArticleHrefByArticle(article, locale),
		[article, baseUrl, locale],
	);
};

export const useRoveFocus = (
	resultLength: number,
	input: HTMLInputElement | null,
) => {
	const [currentFocus, setCurrentFocus] = useState<number | undefined>(
		undefined,
	);

	const handleArrowDown = useCallback(
		(e: React.KeyboardEvent) => {
			e.preventDefault();

			if (currentFocus === undefined) {
				// coming from input, should focus first element
				setCurrentFocus(0);
			} else {
				if (currentFocus === resultLength - 1) {
					// coming from the last element, should focus input
					input?.focus();

					setCurrentFocus(undefined);
				} else {
					// is inside results
					setCurrentFocus(
						currentFocus === resultLength - 1
							? 0
							: currentFocus + 1,
					);
				}
			}
		},
		[resultLength, input, currentFocus],
	);

	const handleArrowUp = useCallback(
		(e: React.KeyboardEvent) => {
			e.preventDefault();

			if (currentFocus === undefined) {
				// coming from input, should focus last element
				setCurrentFocus(resultLength - 1);
			} else {
				if (currentFocus === 0) {
					// coming from first elelement, should focus input
					input?.focus();
					setCurrentFocus(undefined);
				} else {
					// is inside results
					setCurrentFocus(
						currentFocus === 0
							? resultLength - 1
							: currentFocus - 1,
					);
				}
			}
		},
		[resultLength, input, currentFocus],
	);

	return { handleArrowDown, handleArrowUp, currentFocus, setCurrentFocus };
};

export const useCurrencyFormatter = () => {
	const intl = useIntl();

	return useCallback(
		(value: number) =>
			intl.formatNumber(value, {
				style: "currency",
				currency: "EUR",
			}),
		[intl],
	);
};

export const useBrandVariantName = (prefix?: string) => {
	const { slug } = useBrandContext();

	return kebabCase([prefix, slug].filter(truthy).join("-"));
};
