import { useClipboard, useToast, UseToastOptions } from "@chakra-ui/react";
import { BrandSlug } from "../portalstack.config";
import { IntlProvider } from "../context/IntlProvider";
import { defaultBrand, getLocaleByBrand } from "../lib/config";
import { containerStyle } from "../theme/brandThemes/toast";

export const useClipboardInteraction = (value: string, brand?: BrandSlug) => {
	const { onCopy, hasCopied } = useClipboard(value);
	const toast = useToast();
	const locale = getLocaleByBrand(brand ?? defaultBrand.slug);

	return {
		onCopy: (renderToastComponent: UseToastOptions["render"]) => {
			if (!renderToastComponent) {
				// eslint-disable-next-line no-console
				console.warn(
					"useClipboardInteraction: missing renderToastComponent",
				);

				return;
			}

			onCopy();
			toast({
				position: "bottom",
				render: (props) => (
					<IntlProvider locale={locale}>
						{renderToastComponent(props)}
					</IntlProvider>
				),
				containerStyle,
			});
		},
		value,
		hasCopied,
	};
};
