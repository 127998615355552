import { ChakraProvider } from "@chakra-ui/react";
import Head from "next/head";
import React, { useEffect, useMemo } from "react";
import { renderMetaTags, TitleMetaLinkTag } from "react-datocms";
import { BreadcrumbList, Organization, WebSite, WithContext } from "schema-dts";
import dynamic from "next/dynamic";
import { BrandSlug } from "../portalstack.config";
import { IntlProvider } from "../context/IntlProvider";
import { RewriteContext } from "../context/RewriteContext";
import { useScrollDirection } from "../hooks/useScrollDirection";
import { BrandSettings } from "../lib/brandSettings";
import { getBaseUrlByBrandSlug, getBrandBySlug } from "../lib/config";
import { useBrandTheme } from "../theme/useBrandTheme";
import { SourceDebugOutput } from "./SourceDebugOutput";
import { Content } from "./Content";
import { useBuildInfo } from "../hooks";
import { StructuredContent } from "./StructuredContent/StructuredContent";
import { BrandContextProvider } from "../context/BrandContext";
import { TrackingContextProvider } from "../lib/gtm/TrackingContext";
import { useGTMTrackQueryParamEntry } from "../lib/gtm/dataLayer";
import { joinPaths } from "../utils/misc";
import { MaintenancePage } from "./MaintenancePage";
import { getManifestByBrandSlug } from "../lib/cmsManifest";
import { PageType } from "../utils/types";
import { useGTMNavigationEvents } from "../lib/gtm/GTM";
import { CookiebotScript } from "./cookieConsent";

const EditorTools = dynamic(
	async () =>
		import("./EditorTools/EditorTools").then((mod) => mod.EditorTools),
	{
		ssr: false,
	},
);

export type SharedBrandWrapperProps = {
	title: string;
	brandSlug: BrandSlug;
	brandSettings: BrandSettings;
	structuredWebsiteContent?: WithContext<WebSite>;
	structuredBreadcrumbListContent?: WithContext<BreadcrumbList>;
	isPreview: boolean;
	canonicalHref: string | false;
	pageType?: PageType;
};

type BrandWrapperProps = SharedBrandWrapperProps & {
	metaTags: Array<TitleMetaLinkTag>;
	structuredOrganizationContent?: WithContext<Organization>;
	forceNoIndex?: boolean;
};

export const BrandWrapper: ReactFC<BrandWrapperProps> = ({
	brandSlug,
	brandSettings: settings,
	children,
	metaTags,
	structuredWebsiteContent,
	structuredBreadcrumbListContent,
	structuredOrganizationContent,
	title,
	isPreview,
	canonicalHref,
	forceNoIndex,
	pageType,
}) => {
	const theme = useBrandTheme(brandSlug);

	const brand = useMemo(() => getBrandBySlug(brandSlug), [brandSlug]);
	const brandLabel = settings.label ?? brand.slug;
	const canonicalUrl = useMemo(
		() =>
			joinPaths(getBaseUrlByBrandSlug(brand.slug), canonicalHref || null),
		[brand.slug, canonicalHref],
	);

	useGTMNavigationEvents(brand.gtmId);
	useGTMTrackQueryParamEntry();
	useScrollDirection();

	const buildInfo = useBuildInfo(brandSlug);
	const isCMSMaintenanceMode = settings.maintenanceMode === true;
	const showMaintenanceOverlay = isCMSMaintenanceMode && !isPreview;

	const semanticMetaData = useMemo(
		() => [
			{ type: "og:site_name", content: brandLabel },
			{ type: "dc.title", content: title },
			{ type: "dc.publisher", content: brandLabel },
			{ type: "dc.language", content: brand.locale },
		],
		[brand.locale, brandLabel, title],
	);

	useEffect(() => {
		document.body.dataset.hasTopBanner = Boolean(
			settings.topBannerLink && settings.topBannerLabel,
		).toString();
	}, [settings.topBannerLabel, settings.topBannerLink]);

	return (
		<IntlProvider locale={brand.locale}>
			<CookiebotScript cookiebotId={brand.cookiebotId}>
				<TrackingContextProvider value={{ brandLabel }}>
					<SourceDebugOutput data={buildInfo} />

					<RewriteContext.Provider
						value={{
							...brand,
							pageType,
							brandId: settings.id,
							label: settings.label ?? brand.slug,
							...getManifestByBrandSlug(brand.slug),
						}}
					>
						<ChakraProvider theme={theme}>
							<BrandContextProvider value={settings}>
								{showMaintenanceOverlay ? (
									<MaintenancePage />
								) : (
									<>
										<SourceDebugOutput data={brand} />
										<Head>
											{canonicalHref && (
												<>
													<link
														rel="canonical"
														href={canonicalUrl}
													/>
												</>
											)}

											{semanticMetaData.map(
												({ type, content }) => (
													<meta
														key={type}
														property={type}
														content={content}
													/>
												),
											)}

											<link
												rel="icon"
												href={`/favicon-48.png?v=${process.env.FAVICON_CACHEBUSTER}`}
												type="image/png"
											/>
											<link
												rel="icon"
												href={`/favicon.svg?v=${process.env.FAVICON_CACHEBUSTER}`}
												type="image/svg+xml"
											/>
											<link
												rel="apple-touch-icon"
												href={`/favicon-apple-touch.png?v=${process.env.FAVICON_CACHEBUSTER}`}
											/>
											<link
												rel="manifest"
												href="/manifest.json"
											/>

											{renderMetaTags(metaTags)}

											<title>{title}</title>
											<meta
												name="theme-color"
												content="#ffffff"
											/>
											{settings.noindex === true ||
											forceNoIndex === true ? (
												<meta
													name="robots"
													content="noindex"
												/>
											) : (
												<meta
													name="robots"
													content="index, follow, max-snippet:-1"
												/>
											)}
										</Head>
										{structuredWebsiteContent && (
											<StructuredContent
												schema={
													structuredWebsiteContent
												}
											/>
										)}
										{structuredBreadcrumbListContent && (
											<StructuredContent
												schema={
													structuredBreadcrumbListContent
												}
											/>
										)}
										{structuredOrganizationContent && (
											<StructuredContent
												schema={
													structuredOrganizationContent
												}
											/>
										)}
										<Content
											data-boxed-layout={brand.isBoxedLayout.toString()}
											settings={settings}
										>
											{children}
										</Content>
										<EditorTools
											isPreview={Boolean(isPreview)}
										/>
									</>
								)}
							</BrandContextProvider>
						</ChakraProvider>
					</RewriteContext.Provider>
				</TrackingContextProvider>
			</CookiebotScript>
		</IntlProvider>
	);
};
