import React from "react";
import startCase from "lodash/startCase";
import kebabCase from "lodash/kebabCase";
import { GetLandingPageBySlugQuery } from "../__generated__/types/graphql-codegen";

export const removePrefixFromString = (prefix: string, s: string) => {
	return s.startsWith(prefix) ? s.slice(prefix.length) : s;
};

export const prefixString = (prefix: string, s: string) => {
	return prefix + s;
};

export const ITEMS_PER_RESULT_PAGE = 10;

/**
 * Typesafe typeof value === "object"
 */
export const isRecordOrNull = (
	value: unknown,
): value is Record<string, unknown> | null => {
	return typeof value === "object";
};

export const isEmptyChildren = (children: any): boolean =>
	React.Children.count(children) === 0;

/**
 * Find the first occuring (string) value in any object by key.
 * Matches within keys as well (case insensitive!)
 *
 * const foo = {
 *   bar: {
 *     baz: {
 *       myTitle: "hello" // <-- this will match!
 *     },
 *     boo: {
 *       title: "world"
 *     }
 *   }
 * }
 *
 * findValueByKeyRecursive(foo, "title") === "hello"
 *
 */
export const findValueByKeyRecursive = (
	obj: Record<string, unknown>,
	matches: string,
): string | boolean | number | undefined => {
	const entries = Object.entries(obj);

	matches = matches.toLowerCase();

	return entries
		.slice(0) // copy because we mutate later on
		.sort(([akey, aValue], [bkey, bValue]) => {
			// sort to eventually stop early and remove the need to do recursion
			if (typeof aValue === typeof bValue) {
				return 0;
			}

			if (isRecordOrNull(aValue) && !isRecordOrNull(bValue)) {
				return 1;
			}

			if (isRecordOrNull(bValue) && !isRecordOrNull(aValue)) {
				return -1;
			}

			return 0;
		})
		.reduce<string | boolean | number | undefined>(
			(match, [key, value], index, arr) => {
				if (
					(typeof value === "string" ||
						typeof value === "boolean" ||
						typeof value === "number") &&
					key.toLowerCase().includes(matches)
				) {
					arr.splice(0); // we have a match, stop the reduce iteration by mutating the array

					return match ?? value;
				}

				if (Array.isArray(value)) {
					arr.splice(0); // we have a match, stop the reduce iteration by mutating the array

					return match;
				}

				if (isRecordOrNull(value)) {
					// only do recursion if we don't already have a match
					if (!match && value !== null) {
						return (
							findValueByKeyRecursive(value, matches) ?? undefined
						);
					}
				}

				return match;
			},
			undefined,
		);
};

/**
 * slugify text ("Lorem ipsum dolor") to kebab case (`lorem-ipsum-dolor`) with a cutoff
 */
export const textToFragmentId = (str: string, wordCount = 5) =>
	kebabCase(startCase(str.split(" ").slice(0, wordCount).join(" ")));

export const censoredString = (len: number) =>
	[...new Array(len)].map((_) => "*").join("");

export const censoredCompany: NonNullable<
	NonNullable<GetLandingPageBySlugQuery["landingPage"]>["company"]
> = {
	__typename: "CompanyRecord",
	id: "censored-company-id",
	documents: [],
	name: censoredString(12),
	wkn: censoredString(6),
	isin: censoredString(12),
};
