// These are the default messages in ENGLISH!

export const defaultMessages = {
	"404": "404 - The page could not be found",
	"500": "500 - The server encountered an error",
	articleImagesHeading: "Article images",
	reportImagesHeading: "Report images",
	and: "and",
	article: "{count, plural, one {Article} other {Articles}}",
	documents: "Documents",
	page: "{count, plural, one {Page} other {Pages}}",
	articleInfo: "Information about this article",
	urlSlugArticle: "article",
	urlSlugPressRelease: "pr",
	urlSlugAuthor: "authors",
	urlSlugStock: "stock",
	urlSlugCategories: "categories",
	loadMore: "Load more {items}",
	interestingArticles: "Interesting articles for you",
	itemsArticles: "articles",
	showingArticles: "{count} of {total} Articles",
	showingAllArticles: "{count} Articles",
	urlSlugSearch: "search",
	carouselNext: "Next slide",
	carouselPrev: "Previous slide",
	close: "Close",
	copied: "Copied",
	copy: "Copy",
	defaultErrorMessage: "An unexpected error occurred.",
	emailPlaceholder: "Your email address",
	galleryOpenImage: "Open image enlarged",
	galleryImage: "Gallery image",
	home: "Home",
	menu: "Menu",
	newArticle: "New Article",
	currentArticles: "Current Articles",
	newsAndViews: "News & Views",
	newsletterConfirmation:
		"You have successfully subscribed to our newsletter.",
	newsletterConfirmationThanks:
		"Thank you for your confirmation. From now on you will receive the free {brand} Newsletter.",
	newsletterCTAHeader: "Newsletter",
	newsletterCTAButton: "Subscribe to newsletter",
	newsletterCTAText: "Always up to date? Subscribe to our newsletter.",

	// branded
	marketbriefNewsletterCTAHeader: "Get your briefing now!",
	marketbriefNewsletterCTAText:
		"Subscribe to out market briefings, easy and free.",
	marketbriefNewsletterCTAButton: "Subscribe to Market Brief",

	markettimesNewsletterCTAHeader: "Receive new content by mail",
	markettimesNewsletterCTAText:
		"Sign up for the MarketTimes newsletter easily and for free",
	markettimesNewsletterCTAButton: "Subscribe to MarketTimes",

	// end branded

	newsletterCTADisclaimer:
		"When you enter your data, you consent to receiving further communications in accordance with our privacy policy.",
	newsletterThanks: "Thank you for subscribing to our newsletter.",
	newsletterPleaseConfirm:
		"To confirm your email address, please click the link in the email we just sent you.",
	newsletterNoEmail:
		"Email not found? Please also check your spam folder. Also, take note of our {policy}.",
	newsletterResubscribe:
		"We would be pleased to welcome you again as a newsletter subscriber.",
	newsletterResubscribeButton: "Resubscribe",
	newsletterUnsubscribeConfirmation:
		"You have successfully unsubscribed from our mailing list.",
	newsletterUnsubscribeFeedback:
		"We are constantly working on expanding our offer.",
	search: "Search",
	searchResult:
		"{count} of {total, plural, one {# Result} other {# Results}} ",
	shareArticle: "Share this article",
	skipLinkLabel: "Jump directly to main content",
	toggleColorMode: "Toggle color mode",
	wkn: "WKN",
	isin: "ISIN",
	symbol: "Symbol",
	on: "on",
	off: "off",
	marketIdentifier: "Market Identifier",
	industry: "Industry",
	popularArticles: "Popular Articles",
	price: "Current Price",
	priceTarget: "Target Price",
	priceChance: "Chance of Price",
	address: "Address",
	recommendation: "Recommendation",
	author: "Author",
	category: "Category",
	company: "Company",
	tag: "Tag",
	by: "by",
	published: "Published",
	updated: "Updated",
	riskNotice: "Risk Notice",
	backToHomePage: "Back To Homepage",
	toHomePage: "To Homepage",
	bookmarkLabel: "Copy URL to clipboard",
	copiedLong: "{label} copied to Clipboard!",
	copyLong: "Copy {label} to Clipboard",
	toastCloseLabel: "Close notification",
	url: "URL",
	userAgreement: "User Agreement",
	bookmarkToast: "URL copied!",
	otherBrands: "Other brands",
	backTo: "Back to:",
	allCompanyArticles: "All {name} articles",
	modalClose: "Close this modal",
	privacyPolicy: "Privacy Policy",
	legalNotice: "Legal Notice",
	dataPrivacySettings: "Data privacy settings",
	dataPrivacySettingsDescription:
		"To view this content you need to adjust your data privacy settings.",
	searchPlaceholder: "What are you looking for?",
	noResults: "No results found",
	maintenanceModeTitle: "{label} is in maintenance mode …",
	maintenanceModeSubtitle: "Please try again later.",
	articleWallTitle: "You can read this article on {brandLabel}",
	articleWallText:
		"Market World is the financial portal for current information from the world of stock exchanges: shares, DAX, economic news, stock exchange prices, trading tips, sentiment indicators, indices and even more.",
	articleWallCTA: "To the {brandLabel} article",
	relevantArticlesToTopic: "Related articles",
	toArticle: "To article",
	landingPageSubscribeMenuItem: "Newsletter",
};
