import { chakra, ChakraProps, HStack, List } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import React from "react";
import { useBrandLogo } from "../../brandComponents";
import { useRewriteContext } from "../../context/RewriteContext";
import { CurrentDateProps } from "../CurrentDate/CurrentDate";
import { Link } from "../Link/Link";
import { SearchDesktop } from "../Search/SearchDesktop";
import { useHeaderStyles } from "./Header";
import { MenuItem } from "./MenuItem";
import { HeaderProps } from "./types";

const CurrentDate = dynamic<CurrentDateProps>(
	async () =>
		import("../CurrentDate/CurrentDate").then((mod) => mod.CurrentDate),
	{ ssr: false },
);

export const DesktopMenu: ReactFC<
	{ mainMenuItems: HeaderProps["mainMenuItems"] } & ChakraProps
> = ({ mainMenuItems, ...rest }) => {
	// TODO: used for burgerButton, add back in later
	// const { isOpen, onToggle } = useDisclosure();

	const styles = useHeaderStyles();

	const Logo = useBrandLogo();
	const { pageType } = useRewriteContext();

	return (
		<chakra.div
			className={[
				"desktop-wrapper",
				pageType === "landingPage" ? "is-landing-page" : "",
			].join(" ")}
			__css={styles.desktopWrapper}
			{...rest}
			order={2}
		>
			<Link href="/" sx={styles.logoWrapper}>
				<Logo __css={styles.logo} />
			</Link>
			<chakra.div __css={styles.navWrapper}>
				<chakra.nav __css={styles.nav}>
					<chakra.div __css={styles.navItems}>
						<List sx={styles.linkList}>
							{mainMenuItems.map((item, index) => (
								<MenuItem
									index={index}
									key={item.id}
									{...item}
								/>
							))}
						</List>
						<HStack spacing={1} pl={6}>
							<SearchDesktop />
							{/* TODO: add back burger menu, you may need to adjust the search styling if it readded */}
							{/* <BurgerButton
								isOpen={isOpen}
								onClick={onToggle}
								color={foregroundColor}
								minHeight={12}
							/> */}
						</HStack>
					</chakra.div>
				</chakra.nav>
			</chakra.div>
			<HStack spacing={4} order={1} sx={styles.widgetsWrapper}>
				<CurrentDate className="desktop-menu-current-date" />
				{/* <Switch isChecked={isDarkMode} onChange={toggleColorMode}>
					<VisuallyHidden>
						{t("toggleColorMode", {
							state: isDarkMode ? t("off") : t("on"),
						})}
					</VisuallyHidden>
				</Switch> */}
			</HStack>
		</chakra.div>
	);
};
