import {
	chakra,
	ChakraProps,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerFooter,
	List,
} from "@chakra-ui/react";
import React from "react";
import { useBrandLogo } from "../../brandComponents";
import { useCompanyContext } from "../../context/CompanyContext";
import { useSingletonDisclosure } from "../../hooks/useSingletonDisclosure";
import { truthy } from "../../utils/misc";
import { BurgerButton } from "../BurgerButton/BurgerButton";
import { CompanyWidget } from "../CompanyWidget/CompanyWidget";
import { Link } from "../Link/Link";
import { SearchMobile } from "../Search/SearchMobile";
import { useHeaderStyles } from "./Header";
import { MenuItem } from "./MenuItem";
import { HeaderProps } from "./types";

export const MobileMenu: ReactFC<
	{
		mainMenuItems: HeaderProps["mainMenuItems"];
		secondaryMenuItems: HeaderProps["secondaryMenuItems"];
	} & ChakraProps
> = ({ mainMenuItems, secondaryMenuItems, ...rest }) => {
	const { isOpen, onToggle, onClose } = useSingletonDisclosure();

	const Logo = useBrandLogo();
	const styles = useHeaderStyles();
	const companyContext = useCompanyContext();

	return (
		<chakra.div __css={styles.mobileWrapper} {...rest}>
			<SearchMobile />
			<Link href="/" sx={styles.logoWrapper}>
				<Logo __css={styles.logo} />
			</Link>
			<BurgerButton isOpen={isOpen} onClick={onToggle} />
			<Drawer
				isOpen={isOpen}
				placement="top"
				onClose={onClose}
				size="full"
				variant="mobile-menu"
				preserveScrollBarGap
				// eslint-disable-next-line jsx-a11y/no-autofocus
				autoFocus={false}
			>
				<DrawerContent sx={styles.mobileDrawer}>
					<DrawerBody>
						{companyContext?.company &&
							companyContext.mobileWidgetViewConfig && (
								<CompanyWidget
									py={6}
									variant="overlay"
									company={companyContext.company}
									companyWidgetConfig={
										companyContext.mobileWidgetViewConfig
									}
								/>
							)}
						<List sx={styles.linkList}>
							{mainMenuItems.map((item, index) => (
								<MenuItem
									key={item.id}
									index={index}
									variant="menu-link-mobile"
									onClick={() => onClose()}
									{...item}
								/>
							))}
						</List>
					</DrawerBody>

					{secondaryMenuItems.length > 0 && (
						<DrawerFooter>
							<List sx={styles.linkList}>
								{secondaryMenuItems
									.filter(truthy)
									.map((item, index) => (
										<MenuItem
											key={item.id}
											index={mainMenuItems.length + index}
											variant="menu-link-mobile"
											onClick={() => onClose()}
											{...item}
										/>
									))}
							</List>
						</DrawerFooter>
					)}
				</DrawerContent>
			</Drawer>
		</chakra.div>
	);
};
