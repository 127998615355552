import {
	chakra,
	ChakraProps,
	createStylesContext,
	Divider,
	DividerProps,
} from "@chakra-ui/react";
import { normalizeResponsiveImageData } from "../../lib/normalizeGalleryData";
import { ArticleWithUrlPrefix } from "../../types/misc";
import { createBooleanContext } from "../../utils/booleanContext";
import { CMSAsset, CMSAssetProps } from "../CMSAsset/CMSAsset";
import { Link } from "../Link/Link";

const [useIsInList, IsInListProvider] = createBooleanContext(false);

export const [CompanyWidgetStylesProvider, useCompanyWidgetStyles] =
	createStylesContext("CompanyWidget");

export { CompanyWidgetHeaderLink } from "./CompanyWidgetHeaderLink";
export { CompanyWidgetInfoCopyButton } from "./CompanyWidgetInfoCopyButton";

export const CompanyWidgetWrapper: ReactFC<ChakraProps> = ({
	children,
	...rest
}) => {
	const styles = useCompanyWidgetStyles();

	return (
		<chakra.div __css={styles.widget} {...rest} data-cy="company-widget">
			{children}
		</chakra.div>
	);
};

export const CompanyWidgetInfoList: ReactFC<ChakraProps> = ({
	children,
	...rest
}) => {
	const styles = useCompanyWidgetStyles();

	return (
		<IsInListProvider value={true}>
			<chakra.ul __css={styles.infoList} {...rest}>
				{children}
			</chakra.ul>
		</IsInListProvider>
	);
};

export const CompanyWidgetInfoRow: ReactFC<ChakraProps> = ({
	children,
	...rest
}) => {
	const styles = useCompanyWidgetStyles();

	return (
		<chakra.li data-info-row __css={styles.infoRow} {...rest}>
			{children}
		</chakra.li>
	);
};

export const CompanyWidgetInfoRowRight: ReactFC<ChakraProps> = ({
	children,
	...rest
}) => {
	const styles = useCompanyWidgetStyles();

	return (
		<chakra.div __css={styles.infoRowRight} {...rest}>
			{children}
		</chakra.div>
	);
};

export const CompanyWidgetInfoRowLeft: ReactFC<ChakraProps> = ({
	children,
	...rest
}) => {
	const styles = useCompanyWidgetStyles();

	return (
		<chakra.div __css={styles.infoRowLeft} {...rest}>
			{children}
		</chakra.div>
	);
};

export const CompanyWidgetDivider: ReactFC<DividerProps> = (props) => {
	const styles = useCompanyWidgetStyles();
	const isInList = useIsInList();

	if (isInList) {
		return (
			<chakra.li data-divider-wrapper __css={styles.infoRow}>
				<Divider variant="solid" {...props} />
			</chakra.li>
		);
	}

	return <Divider variant="solid" sx={styles} {...props} />;
};

export const CompanyWidgetLogo: ReactFC<
	ChakraProps & {
		name?: string;
		logo: NonNullable<ArticleWithUrlPrefix["company"]>["logo"];
	}
> = ({ logo, name, ...props }) => {
	const styles = useCompanyWidgetStyles();

	return (
		<CMSAsset
			format={logo?.format}
			url={logo?.url}
			alt={logo?.alt ?? `Logo ${name ?? ""}`}
			sx={styles.logo}
			responsiveImage={
				normalizeResponsiveImageData(logo?.responsiveImage) ?? undefined
			}
			{...props}
		/>
	);
};

export const CompanyWidgetHeader: ReactFC<ChakraProps> = ({
	children,
	...rest
}) => {
	const styles = useCompanyWidgetStyles();

	return (
		<chakra.header __css={styles.header} {...rest}>
			{children}
		</chakra.header>
	);
};

export const CompanyWidgetInfoInnerRow: ReactFC<ChakraProps> = ({
	children,
}) => {
	const styles = useCompanyWidgetStyles();

	return <chakra.div __css={styles.infoInnerRow}>{children}</chakra.div>;
};

export const CompanyWidgetLink: ReactFC<{
	url: string;
	isBottomLink?: boolean;
}> = ({ url, isBottomLink = false, children }) => {
	const styles = useCompanyWidgetStyles();

	const linkStyles = {
		...styles.link,
		...(isBottomLink ? styles.bottomLink : {}),
	};

	return (
		<Link sx={linkStyles} target="_blank" rel="noreferrer" href={url}>
			{children}
		</Link>
	);
};

export const CompanyWidgetBottom: ReactFC<ChakraProps> = ({ children }) => {
	const styles = useCompanyWidgetStyles();

	return <chakra.div __css={styles.bottom}>{children}</chakra.div>;
};

export const CompanyWidgetLinkLogo: ReactFC<
	CMSAssetProps & {
		isBottomLink?: boolean;
	}
> = ({ children, isBottomLink, ...rest }) => {
	const styles = useCompanyWidgetStyles();

	const linkLogoStyles = isBottomLink
		? { ...styles.linkLogo, ...styles.bottomLinkLogo }
		: styles.linkLogo;

	return (
		<CMSAsset sx={linkLogoStyles} {...rest}>
			{children}
		</CMSAsset>
	);
};
