import { chakra } from "@chakra-ui/react";
import { ResponsiveImageType } from "react-datocms";
import { replaceCDNHostInURL } from "../../lib/cdnRewrite";
import { CMSImage, CMSImageProps } from "../CMSImage";

export type CMSAssetProps = Omit<CMSImageProps, "responsiveImage"> & {
	responsiveImage?: ResponsiveImageType;
	format?: string;
	url?: string;
};

export const CMSAsset: ReactFC<CMSAssetProps> = ({
	alt,
	children,
	responsiveImage,
	title,
	url,
	pictureStyle,
	divStyle,
	format = "jpg",
	...rest
}) => {
	if (format === "svg" && url) {
		const src = replaceCDNHostInURL(url);

		return (
			<chakra.figure
				position="relative"
				title={title ?? undefined}
				minHeight="1em"
				{...rest}
			>
				<chakra.img
					src={src}
					// leaving these inline since they should never be overwritten
					position="absolute"
					alt={alt}
					top={0}
					left={0}
					width="100%"
					height="100%"
					objectFit="contain"
				/>

				{title && <chakra.figcaption>{title}</chakra.figcaption>}
				{children}
			</chakra.figure>
		);
	}

	if (!responsiveImage) {
		return title ? <>{title}</> : null;
	}

	return (
		<CMSImage
			responsiveImage={responsiveImage}
			alt={alt}
			title={title}
			divStyle={divStyle}
			pictureStyle={pictureStyle}
			{...rest}
		>
			{children}
		</CMSImage>
	);
};
