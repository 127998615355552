import parseISO from "date-fns/parseISO";
import subMinutes from "date-fns/subMinutes";
import { useCallback, useContext, useEffect } from "react";
import { useEffectOnce } from "react-use";
import { RewriteContext } from "../../context/RewriteContext";
import { isSSR } from "../../utils/misc";
import { useTrackingContext } from "./TrackingContext";

type EventCategory = "Analyse" | "Content" | "MicroConversion";
type EventType = "consentInitialized" | "dynamicEvent";

type EventInfo = {
	event?: EventType;
	eventCategory?: EventCategory;
};

type EventInput = EventInfo & {
	eventAction: string;
	eventLabel:
		| string
		| Record<
				string,
				| string
				| number
				| boolean
				| Record<string, string | number | boolean>
		  >;
};

export type LinkClickMouseEvent = React.MouseEvent<
	HTMLAnchorElement,
	MouseEvent
>;

const defaultEventData: EventInfo = {
	event: "dynamicEvent",
	eventCategory: "Analyse",
};

const layerPush = (data: Record<string, unknown>) => {
	if (process.env.MMG_DEBUG_MODE || typeof window.dataLayer === "undefined") {
		// eslint-disable-next-line no-console
		console.log("dataLayer.push(", data);

		return;
	}

	window.dataLayer.push(data);
};

const push = (event: EventInput) => {
	if (isSSR) {
		return;
	}

	const data = {
		...defaultEventData,
		...event,
	};

	layerPush({
		...data,

		eventLabel: JSON.stringify(data.eventLabel, null, 2),
	});
};

const eventsPushed: Array<string> = [];

const pushOnce = (event: EventInput, id: string) => {
	if (eventsPushed.includes(id)) {
		return;
	}

	eventsPushed.push(id);
	push(event);
};

const useSharedTrackingData = () => {
	const context = useTrackingContext();

	const rewriteContext = useContext(RewriteContext);

	const newArticle = context?.article?._firstPublishedAt
		? parseISO(context.article._firstPublishedAt) >
		  subMinutes(new Date(), 60)
		: false;

	return {
		...context,
		newArticle,
		brandLabel: context?.brandLabel ?? rewriteContext?.label ?? "",
	};
};

export type ArticleTrackingType =
	| "article_teaser_click"
	| "subbrand_clickout"
	| "top_bar_click";

export const useGTMTrackNewsletterSignup = () => {
	const { queryParams, brandLabel } = useSharedTrackingData();

	return useCallback(() => {
		push({
			eventCategory: "Analyse",
			eventAction: "newsletter_anmeldung",
			eventLabel: {
				SOI: "SOI",
				title: document.title,
				url: window.location.href,
				gclid: queryParams?.gclid ?? "",
				brand: brandLabel,
			},
		});
	}, [brandLabel, queryParams]);
};

export type NewsletterStatusTrackingType =
	| "newsletter_bestaetigung"
	| "newsletter_abmeldung";

export const useGTMTrackNewsletterStatusChange = (
	eventAction: NewsletterStatusTrackingType,
) => {
	const { queryParams, brandLabel } = useSharedTrackingData();

	return useEffect(() => {
		push({
			eventCategory: "Analyse",
			eventAction,
			eventLabel: {
				DOI: "DOI",
				title: document.title,
				url: window.location.href,
				gclid: "",
				brand: brandLabel,
			},
		});
	}, [brandLabel, queryParams, eventAction]);
};

export const useGTMTrackArticleTeaserClick = (
	eventAction?: ArticleTrackingType,
) => {
	const { article, brandLabel, newArticle } = useSharedTrackingData();

	return useCallback(
		(mouseEvent: LinkClickMouseEvent) => {
			if (!eventAction) {
				return;
			}

			push({
				eventAction,
				eventLabel: {
					brand: brandLabel,
					company: article?.company?.name ?? "",
					WKN: article?.company?.wkn ?? "",
					url: mouseEvent.currentTarget.href,
					newArticle,
					headline: article?.title ?? "",
				},
			});
		},
		[article, brandLabel, eventAction, newArticle],
	);
};

export const useGTMSponsoredPostClick = () => {
	const { article, brandLabel, articleImage, newArticle } =
		useSharedTrackingData();

	return useCallback(
		(mouseEvent: LinkClickMouseEvent) => {
			push({
				eventCategory: "MicroConversion",
				eventAction: "sponsored_post_click",
				eventLabel: {
					brand: brandLabel,
					company: article?.company?.name ?? "",
					WKN: article?.company?.wkn ?? "",
					url: mouseEvent.currentTarget.href,
					newArticle,
					headline: article?.title ?? "",
					pictureURL: articleImage?.src ?? "",
					pictureDescription: articleImage?.desc ?? "",
				},
			});
		},
		[article, articleImage, brandLabel, newArticle],
	);
};

export const useGTMTrackQueryParamEntry = () => {
	const { queryParams, article, brandLabel } = useSharedTrackingData();

	return useEffect(() => {
		if (!queryParams || (window as any).__MMG_queryParamEventTriggered) {
			return;
		}

		push({
			eventAction: "marketingmassnahme",
			eventLabel: {
				brand: brandLabel,
				slug: article?.slug ?? "",
				url: window.location.href,
				path: window.location.pathname,
				...queryParams,
			},
		});

		window.__MMG_queryParamEventTriggered = true;
	}, [article?.slug, brandLabel, queryParams]);
};

export const useGTMTrackBreadcrumbClick = (category: string, level: number) => {
	const { brandLabel } = useSharedTrackingData();

	return useCallback(
		() =>
			push({
				eventAction: "breadcrumb_click",
				eventLabel: {
					brand: brandLabel,
					category,
					level: level - 1,
				},
			}),
		[brandLabel, category, level],
	);
};

export const useGTMTrackTagClick = () => {
	const { brandLabel, article } = useSharedTrackingData();

	return useCallback(
		(tag: string) =>
			push({
				eventAction: "tag_click",
				eventLabel: {
					brand: brandLabel,
					company: article?.company?.name ?? "",
					WKN: article?.company?.wkn ?? "",
					tag,
				},
			}),
		[article?.company?.name, article?.company?.wkn, brandLabel],
	);
};

export const useGTMTrackCompanyNameClick = (
	companyName?: string,
	wkn?: string,
) => {
	const { brandLabel } = useSharedTrackingData();

	return useCallback(
		() =>
			push({
				eventAction: "company_name_click",
				eventLabel: {
					brand: brandLabel,
					company: companyName ?? "",
					WKN: wkn ?? "",
				},
			}),
		[brandLabel, companyName, wkn],
	);
};

export const useGTMTrackCopyButtonClick = (
	companyName?: string,
	wkn?: string,
) => {
	const { brandLabel } = useSharedTrackingData();

	return useCallback(
		(placement: "header" | "sidebar", type: string, value: string) =>
			push({
				eventCategory: "MicroConversion",
				eventAction: `copy_${type}`,
				eventLabel: {
					placement,
					brand: brandLabel,
					[type]: value,
				},
			}),
		[brandLabel],
	);
};

export const useGTMTrackShareClick = (companyName?: string, wkn?: string) => {
	const { brandLabel } = useSharedTrackingData();

	return useCallback(
		(medium: string) =>
			push({
				eventCategory: "MicroConversion",
				eventAction: "share_button",
				eventLabel: {
					brand: brandLabel,
					url: window.location.href,
					medium,
				},
			}),
		[brandLabel],
	);
};

export const useGTMTrackSearch = () => {
	const { brandLabel } = useSharedTrackingData();

	return useCallback(
		(query: string) => {
			push({
				eventCategory: "Analyse",
				eventAction: "search",
				eventLabel: {
					brand: brandLabel,
					searchstring: query,
				},
			});
		},
		[brandLabel],
	);
};

export const useGTMNavbarClick = () => {
	const { brandLabel } = useSharedTrackingData();

	return useCallback(
		(
			mouseEvent: LinkClickMouseEvent,
			navbarIndex: number,
			clickText: string,
		) => {
			push({
				eventAction: "navbar_click",
				eventLabel: {
					brand: brandLabel,
					url: mouseEvent.currentTarget.href,
					navbarIndex,
					clickText,
				},
			});
		},
		[brandLabel],
	);
};

export const useGTMCopyBookmarkClick = () => {
	const { brandLabel } = useSharedTrackingData();

	return useCallback(() => {
		push({
			eventCategory: "MicroConversion",
			eventAction: "copy_bookmark",
			eventLabel: {
				brand: brandLabel,
				url: window.location.href,
			},
		});
	}, [brandLabel]);
};

// cf. https://docs.google.com/document/d/1TMOAvvVDNCOWg45DVX39bCW2herZxpWvjUiCq9G2Tjg/edit#heading=h.eun2ilt700zv
// The GTM spec wants this weird object structure, so instead of
// ["A", "B", "C"]
// we need
// {
// 	"level0": "A", // or "tag0" for tags
// 	"level1": "B",
// 	"level2": "C",
// }

const reduceArrayToObject = <T,>(arr: Array<T>, type: string) =>
	arr.reduce((acc, curr, i) => {
		return {
			...acc,
			[`${type}${i}`]: curr,
		};
	}, {});

export const useGTMTrackArticleView = () => {
	const { brandLabel, article } = useSharedTrackingData();

	const isCompany = Boolean(article?.company?.name);

	return useEffectOnce(() => {
		const companyData = {
			name: article?.company?.name ?? "",
			boersenkennung: article?.company?.wkn ?? "",
			kurs: article?.company?.price ?? "",
			branche: article?.company?.industry ?? "",
		};

		push({
			eventCategory: "Content",
			eventAction: isCompany ? "company_angezeigt" : "artikel_angezeigt",
			eventLabel: {
				brand: brandLabel,
				url: window.location.href,
				articleName: article?.title ?? "",
				timestamp: Math.floor(new Date().getTime() / 1000),
				categories: reduceArrayToObject(
					article?.categories ?? [],
					"level",
				),
				tags: reduceArrayToObject(article?.tags ?? [], "tags"),
				...(isCompany ? companyData : {}),
			},
		});
	});
};

export const useGTMTrackLandingPageView = () => {
	const { brandLabel, landingPage } = useSharedTrackingData();

	const isCompany = Boolean(landingPage?.company?.name);

	return useEffectOnce(() => {
		const companyData = {
			name: landingPage?.company?.name ?? "",
			boersenkennung: landingPage?.company?.wkn ?? "",
			kurs: landingPage?.company?.price ?? "",
			branche: landingPage?.company?.industry ?? "",
		};

		push({
			eventCategory: "Content",
			eventAction: isCompany ? "company_angezeigt" : "lp_angezeigt",
			eventLabel: {
				brand: brandLabel,
				url: window.location.href,
				articleName: landingPage?.title ?? "",
				timestamp: Math.floor(new Date().getTime() / 1000),
				...(isCompany ? companyData : {}),
			},
		});
	});
};

export const useGTMTrackCompanyOverlay = () => {
	const { brandLabel, landingPage } = useSharedTrackingData();

	return useCallback(() => {
		const companyData = {
			name: landingPage?.company?.name ?? "",
			boersenkennung: landingPage?.company?.wkn ?? "",
			kurs: landingPage?.company?.price ?? "",
			branche: landingPage?.company?.industry ?? "",
			wkn: landingPage?.company?.wkn ?? "",
		};

		push({
			event: "dynamicEvent",
			eventCategory: "Analyse",
			eventAction: "company_overlay",
			eventLabel: {
				brand: brandLabel,
				company: companyData.name,
				WKN: companyData.wkn,
			},
		});
	}, [landingPage, brandLabel]);
};

export const useGTMDocumentClick = () => {
	const { brandLabel, landingPage } = useSharedTrackingData();

	return useCallback(() => {
		const companyData = {
			name: landingPage?.company?.name ?? "",
			boersenkennung: landingPage?.company?.wkn ?? "",
			kurs: landingPage?.company?.price ?? "",
			branche: landingPage?.company?.industry ?? "",
			wkn: landingPage?.company?.wkn ?? "",
		};

		push({
			event: "dynamicEvent",
			eventCategory: "Analyse",
			eventAction: "unternehmenspraesentation",
			eventLabel: {
				brand: brandLabel,
				company: companyData.name,
				WKN: companyData.wkn,
			},
		});
	}, [landingPage, brandLabel]);
};

export const useGTMTrackSessionDuration = () => {
	return useCallback(() => {
		const url = window.location.href;
		const timesInMinutes = [2, 5, 10];

		const timeoutIds = timesInMinutes.map((time) =>
			setTimeout(
				() => {
					push({
						event: "dynamicEvent",
						eventCategory: "MicroConversion",
						eventAction: "sitzungsdauer",
						eventLabel: {
							time,
							url,
						},
					});
				},
				time * 1000 * 60,
			),
		);

		return () => {
			timeoutIds.forEach((id) => clearTimeout(id));
		};
	}, []);
};

export const useGTMTrackScrollDepth = () => {
	return useCallback(() => {
		const depths = [25, 50, 75, 100];
		const url = window.location.href;

		const handleScroll = () => {
			const position = document.documentElement.scrollTop;
			const scrollHeight =
				document.documentElement.scrollHeight -
				document.documentElement.clientHeight;

			const scrolledInPercent = (position / scrollHeight) * 100;

			depths.forEach((depth) => {
				if (scrolledInPercent >= depth) {
					pushOnce(
						{
							event: "dynamicEvent",
							eventCategory: "MicroConversion",
							eventAction: "scrolltiefe",
							eventLabel: {
								percent: depth,
								url,
							},
						},
						`scroll-depth-${depth}-${url}`,
					);
				}
			});
		};

		document.addEventListener("scroll", handleScroll);

		return () => document.removeEventListener("scroll", handleScroll);
	}, []);
};
