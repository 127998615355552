import { Portal, chakra } from "@chakra-ui/react";
import React from "react";
import { useSearchStyles } from "./SearchDesktop";

type SearchOverlayProps = { isOpen: boolean; onClose: () => void };

export const SearchOverlay: ReactFC<SearchOverlayProps> = ({
	isOpen,
	onClose,
	...rest
}) => {
	const styles = useSearchStyles();

	return isOpen ? (
		<Portal>
			<chakra.div sx={styles.overlay} onClick={onClose} {...rest} />
		</Portal>
	) : null;
};
