import { Brand, BrandSlug } from "../portalstack.config";
import { getManifestByBrandSlug } from "./cmsManifest";
import { getBaseUrlByBrandSlug, getBrandBySlug } from "./config";
import { normalizeSearchResult } from "../utils/normalizeSearchResults";
import {
	ApiReturnType,
	ApiSearchResult,
	DatoClientSearchResult,
} from "../utils/types";
import { truthy } from "../utils/misc";
import { getFromRestAPI } from "./cmsRestApi";

export class SearchService {
	brand: Brand | undefined;
	manifest: ReturnType<typeof getManifestByBrandSlug> | undefined;

	constructor(brand: Brand) {
		this.brand = brand;
		this.manifest = getManifestByBrandSlug(this.brand.slug);
	}

	async search(
		query: string,
		limit: number,
		offset: number,
	): Promise<ApiReturnType<Array<ApiSearchResult>>> {
		if (!this.manifest?.webhookId || !this.brand?.locale) {
			return {
				error: "Search is not configured correctly",
				data: null,
			};
		}

		const { locale } = this.brand;

		const params = new URLSearchParams({
			build_trigger_id: this.manifest.webhookId,
			limit: limit.toString(),
			offset: offset.toString(),
			q: query,
			locale,
		});

		const response = await getFromRestAPI(
			`search-results?${params.toString()}`,
			{
				token: process.env.DATO_API_TOKEN_SEARCH,
				baseUrl: getBaseUrlByBrandSlug(this.brand.slug),
			},
		);

		const json = (await response.json())
			.data as Array<DatoClientSearchResult>;

		return {
			error: null,
			data: json
				.map((result) => normalizeSearchResult(result, locale))
				.filter(truthy)
				.sort((a, b) => b.score - a.score),
		};
	}
}
export const getSearchClient = (brand?: BrandSlug) => {
	if (!brand) {
		return;
	}

	// eslint-disable-next-line consistent-return
	return new SearchService(getBrandBySlug(brand));
};
