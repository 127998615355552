import { chakra, Spinner, VStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslatedString } from "../../i18n/hooks";
import { sortResults } from "../../lib/search";
import { isSSR } from "../../utils/misc";
import { ApiSearchResult } from "../../utils/types";
import { HeadingLevelBoundary, Hx } from "../headings";
import { useSearchStyles } from "./SearchDesktop";
import { SearchSingleResult } from "./SearchSingleResult";

type SearchResultsProps = {
	results: Array<ApiSearchResult>;
	isLoading: boolean;
	searchTerm: string;
	focusIndex: number | undefined;
	onClose: () => void;
};

export const SearchResults: ReactFC<SearchResultsProps> = ({
	results,
	isLoading,
	searchTerm,
	focusIndex,
	onClose,
	...rest
}) => {
	const t = useTranslatedString();
	const styles = useSearchStyles();

	const sortedResults = useMemo(() => {
		return sortResults(results);
	}, [results]);

	if (isLoading) {
		return (
			<chakra.div __css={styles.resultsContainer} {...rest}>
				<Spinner />
			</chakra.div>
		);
	}

	return results.length >= 0 ? (
		<HeadingLevelBoundary>
			<chakra.div {...rest}>
				{results.length === 0 && searchTerm !== "" && (
					<chakra.div __css={styles.resultsContainer}>
						<p>{t("noResults")}</p>
					</chakra.div>
				)}
				{sortedResults.map(({ titleKey, items, type }) => {
					return (
						<chakra.div key={titleKey} data-cy="search-results">
							<Hx sx={styles.resultsHeading}>
								{t(titleKey, { count: items.length })}
							</Hx>
							<VStack
								listStyleType="none"
								display="block"
								as="ol"
							>
								{items.map((item) => {
									return (
										<chakra.li
											key={item.id}
											onClick={() => {
												onClose();
												if (!isSSR) {
													setTimeout(() => {
														window.scrollTo({
															top: 0,
															behavior: "smooth",
														});
													}, 100);
												}
											}}
										>
											<SearchSingleResult
												result={item}
												// attention! check for correct index!
												focus={
													focusIndex === item.index
												}
											/>
										</chakra.li>
									);
								})}
							</VStack>
						</chakra.div>
					);
				})}
			</chakra.div>
		</HeadingLevelBoundary>
	) : null;
};
