import { createContext, useContext, Provider } from "react";

export const createBooleanContext = (
	defaultValue = false,
): [() => boolean, Provider<boolean>] => {
	const booleanContext = createContext<boolean>(defaultValue);
	const Provider = booleanContext.Provider;
	const useSimpleBooleanContext = () => useContext(booleanContext);

	return [useSimpleBooleanContext, Provider];
};
