import { chakra, ChakraProps } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { useRoveFocus } from "../../hooks";
import { useGTMTrackSearch } from "../../lib/gtm/dataLayer";
import { useSearch } from "../../lib/useSearch";
import { useSearchStyles } from "./SearchDesktop";
import { SearchForm } from "./SearchForm";
import { SearchResults } from "./SearchResults";

type SearchProps = {
	onTransitionEnd?: (e: React.TransitionEvent) => void;
	onClose: () => void;
	isOpen: boolean;
	inputRef: React.RefObject<HTMLInputElement>;
};

export const Search: ReactFC<ChakraProps & SearchProps> = ({
	onTransitionEnd,
	isOpen,
	onClose,
	inputRef,
	...rest
}) => {
	const trackSearch = useGTMTrackSearch();
	const styles = useSearchStyles();
	const [searchTerm, setSearchTerm] = useState("");
	const [debouncedSearchTerm, setDebouncedSearchTerm] = React.useState("");

	const {
		data: search,
		isFetching: isLoading,
		refetch,
	} = useSearch(debouncedSearchTerm, {
		enabled: debouncedSearchTerm !== "" && isOpen,
	});

	useDebounce(
		() => {
			setDebouncedSearchTerm(searchTerm);
			if (searchTerm !== "" && isOpen) {
				trackSearch(searchTerm);
			}
		},
		300,
		[searchTerm],
	);

	useEffect(() => {
		// security measurement if you already moved focus down
		if (search?.data?.length === 0 && searchTerm === "") {
			inputRef.current?.focus();
		}
	}, [search, inputRef, searchTerm]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
	};

	const { handleArrowDown, handleArrowUp, currentFocus, setCurrentFocus } =
		useRoveFocus(search?.data?.length ?? 0, inputRef.current);

	const handleKeyDown = (e: React.KeyboardEvent) => {
		switch (e.key) {
			case "Escape": {
				// close and reset
				onClose();
				setCurrentFocus(undefined);
				break;
			}
			case "ArrowDown": {
				if ((search?.data?.length ?? 0) > 0) {
					handleArrowDown(e);
				}
				break;
			}
			case "ArrowUp": {
				if ((search?.data?.length ?? 0) > 0) {
					handleArrowUp(e);
				}
				break;
			}
			case "Tab": {
				// close when tabbing back
				if (e.shiftKey && currentFocus === undefined) {
					onClose();
					setCurrentFocus(undefined);
				}
				break;
			}
			default:
				break;
		}
	};

	return (
		<chakra.div
			__css={styles.innerContainer}
			onKeyDown={handleKeyDown}
			onTransitionEnd={(e) => {
				onTransitionEnd?.(e);
				if (e.propertyName === "transform") {
					setSearchTerm("");
				}
			}}
			{...rest}
		>
			<SearchForm
				onChange={(e) => handleChange(e)}
				value={searchTerm}
				isOpen={isOpen}
				inputRef={inputRef}
				onSubmit={(e) => {
					e.preventDefault();
					refetch();
					// if (searchTerm !== "") {
					// 	setSearchTerm("");
					// 	onClose();
					// 	router.push(`/search/${searchTerm}`);
					// }
				}}
			/>

			<SearchResults
				results={search?.data ?? []}
				isLoading={isLoading}
				searchTerm={searchTerm}
				focusIndex={currentFocus}
				onClose={() => {
					onClose();
					setSearchTerm("");
				}}
			/>
		</chakra.div>
	);
};
