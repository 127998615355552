import {
	chakra,
	ChakraProps,
	IconButton,
	useDisclosure,
	useMultiStyleConfig,
	createStylesContext,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { useRewriteContext } from "../../context/RewriteContext";
import { useColorMode } from "../../hooks";
import { useTranslatedString } from "../../i18n/hooks";
import { Close, Search as SearchIcon } from "../../icons";
import { Search } from "./Search";
import { SearchOverlay } from "./SearchOverlay";

type SearchProps = ChakraProps;
export const [StylesProvider, useSearchStyles] = createStylesContext("Search");

export const SearchDesktop: ReactFC<SearchProps> = ({ ...rest }) => {
	const t = useTranslatedString();
	const { hasSearch } = useRewriteContext();
	const { foregroundColor } = useColorMode();

	const textInputRef = useRef<HTMLInputElement | null>(null);
	const { isOpen, onToggle, onClose } = useDisclosure();
	const styles = useMultiStyleConfig("Search", {});

	if (!hasSearch) {
		return null;
	}

	const handleTransitionEnd = (e: React.TransitionEvent) => {
		if (e.propertyName === "transform") {
			if (isOpen) {
				textInputRef.current?.focus();
			} else {
				textInputRef.current?.blur();
			}
		}
	};

	return (
		<StylesProvider value={styles}>
			<SearchOverlay onClose={onClose} isOpen={isOpen} />
			<chakra.div className={isOpen ? "is-active" : ""} {...rest}>
				<chakra.div aria-hidden={!isOpen} __css={styles.container}>
					<Search
						onTransitionEnd={handleTransitionEnd}
						isOpen={isOpen}
						inputRef={textInputRef}
						onClose={onClose}
					/>
				</chakra.div>
				<IconButton
					data-cy="search-toggle-desktop"
					sx={styles.toggle}
					variant="ghost"
					onClick={(e) => {
						onToggle();
					}}
					aria-label={t("search")}
					onKeyDown={(e) => {
						if (e.key === "Tab") onClose();
					}}
					icon={
						isOpen ? (
							<Close color={foregroundColor} />
						) : (
							<SearchIcon color={foregroundColor} />
						)
					}
				/>
			</chakra.div>
		</StylesProvider>
	);
};
